import React from 'react'
import { useFormContext } from '../../../../context/FormContext'
import { AvatarImgContainer, AvatarSeparator } from '../styles'
import { TInformationStepComponent } from '../types'
import DefaultSecondImg from '../../../../assets/images/defaultSecondImg.png'
import AvatarLogo from '../../../../components/AvatarLogo'
import Text from '../../../../components/text'
import { useTheme } from 'styled-components'

const AvatarToSomething: React.FC<TInformationStepComponent> = ({ stepInfo }) => {
  const theme = useTheme()
  const { avatarInfo } = useFormContext()

  const {
    logoFromChooseAvatar,
    textColor,
    content: { altImage, avatarSecondImg, labelFirstImg, labelSecondImg }
  } = stepInfo
  const altMsg = logoFromChooseAvatar?.avatarVariationAlt || altImage

  return (
    <AvatarImgContainer bg={avatarInfo?.bg}>
      <AvatarLogo
        stepInfo={stepInfo}
        altInfo={altImage}
        label={labelFirstImg}
        showName={!!labelFirstImg}
        textColor={textColor}
      />
      <AvatarSeparator />
      <div className='img'>
        <img src={avatarSecondImg || DefaultSecondImg} alt={altMsg} />
        {labelSecondImg && (
          <Text
            value={labelSecondImg}
            fontSize='1.125rem'
            lineHeight='23px'
            textAlign='center'
            font='body2'
            color={textColor || theme.colors.black}
          />
        )}
      </div>
    </AvatarImgContainer>
  )
}

export default AvatarToSomething
