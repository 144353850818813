import { TForm } from '../../types/types'
import api from '../index'

type TFilesSaved = {
  url: string
  name: string
}

export const getForm = async (id: string) => {
  const response = await api.get<{ data: TForm }>(`form/${id}`)

  return response.data
}

export const postFormAnswers = async (formId: string, data: Record<string, unknown>) => {
  const body = { data, form: { id: formId } }
  const response = await api.post<{ data: TForm }>('answer', body)

  return response.data
}

export const postFormFiles = async (formData: FormData) => {
  const response = api.post<{ files: TFilesSaved[] }>('files', formData)

  return response
}
