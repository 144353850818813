import styled, { DefaultTheme, css } from 'styled-components'
import { space } from 'styled-system'

const getLabelColor = (theme: DefaultTheme, variant?: 'primary' | 'secondary' | 'white') => {
  if (!variant) return theme.colors.black
  if (variant === 'white') return theme.colors.white

  return theme.colors.black
}

export const StyledChooseButtonsContainer = styled.div<{
  type: 'radio' | 'checkbox'
  variant?: 'primary' | 'secondary' | 'white'
  layout?: 'row' | 'column'
  hideLabel?: boolean
}>`
  position: relative;
  display: grid;
  align-items: center;
  width: ${({ layout }) => (layout === 'row' ? 'unset' : '100%')};

  ${({ hideLabel }) =>
    hideLabel
      ? css`
          width: 20px;
          margin: auto;
        `
      : css`
          grid-template-columns: 20px 1fr;
        `};

  input {
    opacity: 0;
    width: 24px;
    height: 24px;
    margin: 0;

    + label {
      font-size: 16px;
      line-height: 1.5;
      cursor: pointer;
      ${props => props.theme.fonts.body}
      color: ${props => getLabelColor(props.theme, props.variant)};

      &::before {
        content: '';
        top: 0;
        left: 0;
        margin: 0;
        width: 20px;
        height: 20px;
        position: absolute;
        border-radius: ${({ type }) => (type === 'radio' ? '50%' : '25%')};
        border: 2px solid ${props => props.theme.colors[props.variant || 'primary']};
        top: 50%;
        left: 10px;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
      }

      &::after {
        content: '';
        box-sizing: border-box;
        opacity: 0;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors[props.variant || 'primary']};
        border-top: 0;
        border-left: 0;
        top: 50%;
        left: 10px;
        transform: translate(-50%, -50%);
        display: block;

        ${({ type }) =>
          type === 'checkbox' &&
          css`
            background-image: ${({ theme }) =>
              `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"%3E%3Cpath fill="${theme.colors.additionalColor.replace('#', '%23')}" d="M22.1135 0H3.44688C1.98021 0 0.780212 1.2 0.780212 2.66667V21.3333C0.780212 22.8 1.98021 24 3.44688 24H22.1135C23.5802 24 24.7802 22.8 24.7802 21.3333V2.66667C24.7802 1.2 23.5802 0 22.1135 0ZM11.0602 17.72C10.5402 18.24 9.70021 18.24 9.18021 17.72L4.39355 12.9333C3.87355 12.4133 3.87355 11.5733 4.39355 11.0533C4.91355 10.5333 5.75355 10.5333 6.27355 11.0533L10.1135 14.8933L19.2869 5.72C19.8069 5.2 20.6469 5.2 21.1669 5.72C21.6869 6.24 21.6869 7.08 21.1669 7.6L11.0602 17.72Z"/%3E%3C/svg%3E')`};
            background-size: contain;
            width: 20px;
            height: 20px;
            border-radius: 25%;
            background-color: transparent;
          `};
      }
    }

    &:checked {
      + label {
        &::after {
          opacity: 1;
        }

        &::before {
          ${({ type }) =>
            type === 'checkbox' &&
            css`
              border: 2px solid ${props => props.theme.colors.additionalColor};
            `};
        }
      }
    }

    &:focus:not(&:checked) {
      + label {
        &::before {
          outline: 2px solid ${props => props.theme.colors['primary']};
        }
      }
    }
  }

  ${space}
`
