export function splitArray<T>(arr: Array<T>, MAX_ITEMS_PER_ARRAY = 10) {
  const result = []

  for (let i = 0; i < arr.length; i += MAX_ITEMS_PER_ARRAY) {
    const chunk = arr.slice(i, i + MAX_ITEMS_PER_ARRAY)
    result.push(chunk)
  }

  return result
}
