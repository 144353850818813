import styled from 'styled-components'

export const AvatarImgContainer = styled.div<{ bg?: string }>`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin: 40px 0;

  > div.img {
    width: 180px;
    justify-content: center;
    flex-direction: column;
    display: flex;

    @media screen and (max-width: 768px) {
      width: 110px;
      height: 110px;
    }
  }
`

export const AvatarSeparator = styled.div`
  display: flex;
  width: 100px;
  height: 0;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.colors.additionalColor};

  @media screen and (max-width: 500px) {
    width: 60px;
  }

  &::before,
  &::after {
    width: 19px;
    height: 19px;
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.colors.additionalColor};
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
  }

  &::after {
    right: 0;
  }
`
