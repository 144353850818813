import { useEffect, useRef } from 'react'
import { StyledSelect, StyledSelectContainer } from './styles'
import { TSelectProps } from './types'

const Select: React.FC<TSelectProps> = ({
  id,
  label,
  options,
  onChange,
  placeholder,
  showLabel,
  value,
  variant,
  autoFocus,
  errorMessage,
  ...spaceProps
}) => {
  const selectElement = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (selectElement.current && autoFocus) {
      selectElement.current.focus()
    }
  }, [autoFocus])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(event)
  }

  return (
    <StyledSelectContainer variant={variant} {...spaceProps}>
      <label>
        {showLabel && label}
        <StyledSelect
          id={id}
          ref={selectElement}
          autoFocus={autoFocus}
          variant={variant}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          aria-label={label}
          aria-invalid={!!errorMessage}
          {...(errorMessage && {
            'aria-describedby': `error-message-${id}`
          })}
        >
          {options.map(({ id, label }) => (
            <option value={id} key={id}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </label>
      {errorMessage && <p id={`error-message-${id}`}>{errorMessage}</p>}
    </StyledSelectContainer>
  )
}

export default Select
