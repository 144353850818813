import { StyledCardLoginContainer, StyledLogoContainer } from './styles'
import { CardLoginProps } from './types'
import Logo from '../../../../assets/images/logo.png'

const CardLogin: React.FC<CardLoginProps> = ({ children }) => {
  return (
    <StyledCardLoginContainer>
      <StyledLogoContainer>
        <img src={Logo} alt='logo' />
      </StyledLogoContainer>
      {children}
    </StyledCardLoginContainer>
  )
}

export default CardLogin
