import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { TSelectProps } from './types'

export const StyledSelectContainer = styled.div<Pick<TSelectProps, 'variant'>>`
  width: 100%;
  position: relative;

  label {
    display: flex;
    flex-direction: column;
    position: relative;

    ${props => props.theme.fonts.body};
    font-weight: 700;
    margin-bottom: 0.3rem;
    color: ${props =>
      props.variant === 'secondary' ? props.theme.colors.white : props.theme.colors.black};
  }

  ${space}

  p[id^="error-message"] {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 100%;
    font-size: 13px;
    ${props => props.theme.fonts.body}
    color: ${props => props.theme.colors.error};
    font-weight: 500;
    line-height: 1;
  }
`

export const StyledSelect = styled.select<{
  variant?: 'primary' | 'secondary'
  placeholder?: string
}>`
  flex: 1;
  border: 2px solid transparent;

  padding: 8px 0;
  font-size: 1rem;
  transition: all 300ms;
  background-color: transparent;
  ${props => props.theme.fonts.body}
  line-height: 1;

  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ variant, theme }) => css`
    color: ${variant === 'secondary' ? theme.colors.white : theme.colors.black};
    border-bottom: 1.5px solid ${variant === 'secondary' ? theme.colors.white : '#000'};
    font-size: 1rem;
  `}
`
