import { useEffect } from 'react'
import { useFormContext } from '../../context/FormContext'
import Container from '../../components/container'
import BreadCrumb from '../../components/breadcrumb'
import Loading from '../../components/Loading'
import { getFormStep } from './util'
import { useLayoutContext } from '../../context/LayoutContext'
import Step from '../Steps/Step'

const Form = () => {
  const { meta, currentStep, steps, changeStepTo, isLoading, formContent, lastVisitedStep } =
    useFormContext()
  const { currentWindowSize } = useLayoutContext()
  const breadcrumbAndButtonsHeight = currentWindowSize === 'lg' ? '100px' : '40px'
  const showBreadcrumb = !steps[currentStep].hideBreadcrumb
  const { formattedStepInfo, children } = getFormStep(steps[currentStep], formContent)

  useEffect(() => {
    const stepTitle = steps[currentStep].title
    document.title = `${meta.title} - ${stepTitle}`
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [currentStep, meta.title, steps])

  return (
    <Container
      main
      display='grid'
      width='clamp(320px, 850px, 100vw)'
      justifyContent='center'
      gridTemplateRows={showBreadcrumb ? `${breadcrumbAndButtonsHeight} 1fr` : ''}
      gridTemplateColumns='1fr'
    >
      <>
        {showBreadcrumb && (
          <BreadCrumb
            onClickStep={changeStepTo}
            currentStep={currentStep}
            size={steps.length - 1}
            lastVisitedStep={lastVisitedStep}
          />
        )}
        {isLoading ? <Loading /> : <Step stepInfo={formattedStepInfo}>{children}</Step>}
      </>
    </Container>
  )
}

export default Form
