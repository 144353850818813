import styled from 'styled-components'
import { space } from 'styled-system'

export const StyledAddressInputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.3rem;
  position: relative;
  ${space}

  p[id^="error-message"] {
    width: 100%;
    margin: 0;
    padding-left: 49px;
    position: absolute;
    top: 100%;
    font-size: 13px;
    ${props => props.theme.fonts.body}
    color: ${props => props.theme.colors.error};
    font-weight: 500;
    line-height: 1;
  }
`
