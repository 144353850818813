import { TSimpleForm } from '../../types/PossibleSteps'
import { TLayoutProps } from '../../types/types'
import Card from '../../pages/Steps/SimpleStep/Layout/Card'
import Color from '../../pages/Steps/SimpleStep/Layout/Color'
import Message from '../../pages/Steps/SimpleStep/Layout/Message'

class SimpleLayoutStrategy {
  static card(layoutProps: TLayoutProps<TSimpleForm>) {
    return <Card {...layoutProps} />
  }

  static message(layoutProps: TLayoutProps<TSimpleForm>) {
    return <Message {...layoutProps} />
  }

  static color(layoutProps: TLayoutProps<TSimpleForm>) {
    return <Color {...layoutProps} />
  }
}

export default SimpleLayoutStrategy
