import { TComplexForm } from '../../types/PossibleSteps'
import { TLayoutProps } from '../../types/types'
import { Avatar } from '../../pages/Steps/ComplexForm/Layout/avatar'
import { CardItemSliders } from '../../pages/Steps/ComplexForm/Layout/CardItemSliders'
import { MultipleCheckbox } from '../../pages/Steps/ComplexForm/Layout/MultipleCheckbox'

class ComplexLayoutStrategy {
  static avatar(layoutProps: TLayoutProps<TComplexForm>) {
    return <Avatar {...layoutProps} />
  }

  static single(layoutProps: TLayoutProps<TComplexForm>) {
    return <CardItemSliders {...layoutProps} />
  }

  static multiple(layoutProps: TLayoutProps<TComplexForm>) {
    return <CardItemSliders {...layoutProps} />
  }

  static multipleCheckbox(layoutProps: TLayoutProps<TComplexForm>) {
    return <MultipleCheckbox {...layoutProps} />
  }
}

export default ComplexLayoutStrategy
