import { useLayoutContext } from '../../../../context/LayoutContext'
import { CardMultiLayers } from '../../../../components/CardMultiLayers'
import AvatarLogo from '../../../../components/AvatarLogo'
import Container from '../../../../components/container'
import FormItems from '../FormItems'

import { TLayoutProps } from '../../../../types/types'
import { TSimpleForm } from '../../../../types/PossibleSteps'
import { ImageFromStep } from '../styles'

const Card: React.FC<TLayoutProps<TSimpleForm>> = ({ stepInfo, formikProps }) => {
  const { isMobile } = useLayoutContext()
  const { logoFromChooseAvatar, logo, logoAlt } = stepInfo
  const showName =
    logoFromChooseAvatar?.showName !== undefined ? logoFromChooseAvatar?.showName : true

  return (
    <CardMultiLayers>
      <Container justifyContent='center' alignItems='center' height='100%' position='relative'>
        {!isMobile && (
          <Container flexDirection='column' justifyContent='center' alignItems='center'>
            {logoFromChooseAvatar ? (
              <AvatarLogo stepInfo={stepInfo} showName={showName} />
            ) : (
              <ImageFromStep src={logo} alt={logoAlt} />
            )}
          </Container>
        )}
        {isMobile && (
          <Container
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            top='-25%'
            right='-40px'
          >
            {logoFromChooseAvatar ? (
              <AvatarLogo stepInfo={stepInfo} showName={false} />
            ) : (
              <ImageFromStep src={logo} alt={logoAlt} />
            )}
          </Container>
        )}
        <Container
          ml={isMobile ? '0' : '30px'}
          flexDirection='column'
          flex={1}
          height='100%'
          justifyContent='space-around'
        >
          <FormItems formikProps={formikProps} stepInfo={stepInfo} />
        </Container>
      </Container>
    </CardMultiLayers>
  )
}

export default Card
