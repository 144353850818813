import { TOption } from '../../types/AdditionalTypes'
import Icon from '../Icon'
import Text from '../text'
import { AccordionStyle } from './style'

type TAccordionItem = {
  item: TOption
  isOpen: boolean
  handleClick: () => void
}

const AccordionItem: React.FC<TAccordionItem> = ({
  item: { label, description, id },
  isOpen,
  handleClick
}) => {
  return (
    <AccordionStyle>
      <button
        id={id}
        type='button'
        onClick={handleClick}
        aria-controls={`${id}-content`}
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        <strong>
          {label}
          <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} size={20} />
        </strong>
      </button>
      <div role='region' id={`${id}-content`} aria-labelledby={id}>
        <Text font='body'>{description}</Text>
      </div>
    </AccordionStyle>
  )
}

export default AccordionItem
