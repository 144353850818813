import { useState } from 'react'
import Text from '../../../components/text'
import Button from '../../../components/button'
import Container from '../../../components/container'
import RadioOrCheckbox from '../../../components/RadioOrCheckbox'
import { useFormContext } from '../../../context/FormContext'
import { TTableComponent, TTableOptions } from './types'
import { StyledTable } from './styles'
import { useLayoutContext } from '../../../context/LayoutContext'
import { StyledForm } from '../SimpleStep/styles'
import { DEFAULT_ERROR_MSG } from '../../../utils/schemaToYup'

const Table: React.FC<TTableComponent> = ({ stepInfo }) => {
  const { isMobile } = useLayoutContext()
  const { formContent, goToNextStep, goToPreviousStep, editFormContent, ...formData } =
    useFormContext()
  const {
    basedOnPreviousQuestion = '',
    content: { variant, headers, content, basedOnPreviousAnswer, tableName, isRequired = false }
  } = stepInfo

  const stepResponse = formContent[basedOnPreviousQuestion] as TTableOptions[]
  const formattedResponse = !Array.isArray(stepResponse) ? [stepResponse] : stepResponse

  const initialTableContent: TTableOptions[] = basedOnPreviousAnswer
    ? formattedResponse
    : content.lines
  const [tableContent, setTableContent] = useState(initialTableContent)
  const [formErrors, setFormErrors] = useState<Record<string, string>>()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newTable = [...tableContent]
    newTable[index].response = event.target.value
    setTableContent(newTable)

    delete formErrors?.[newTable[index].id]
    setFormErrors(formErrors)
  }

  const handleSubmit = () => {
    const errors = tableContent
      .filter(item => item.response === undefined)
      .reduce((acc, item) => ({ ...acc, [item.id]: DEFAULT_ERROR_MSG }), {})
    setFormErrors(errors)

    if (!Object.keys(errors).length) {
      editFormContent({ [basedOnPreviousQuestion]: tableContent })
      goToNextStep()
    }
  }

  const renderContent = (item: TTableOptions, header: string, index: number) => {
    if (content.type === 'radioGroup')
      return (
        <RadioOrCheckbox
          hideLabel={!isMobile}
          id={item.id}
          layout='row'
          variant={variant}
          item={{
            id: `${item.id} ${header}`,
            label: header
          }}
          aria-invalid={!!formErrors?.[item.id]}
          autoFocus={index === 0}
          isChecked={tableContent[index].response === header}
          onChange={event => onChange(event, index)}
          inputType={'radio'}
          isRequired={isRequired}
        />
      )

    return item.label
  }

  return (
    <StyledForm onSubmit={handleSubmit} className='mt-4'>
      <StyledTable role='table'>
        <caption>{tableName}</caption>
        <thead role='rowgroup'>
          <tr role='row'>
            {headers.map(header => (
              <th role='columnheader' key={header}>
                <Text font='body'>{header}</Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody role='rowgroup'>
          {tableContent.map((item, itemIndex) => (
            <tr role='row' key={item.id}>
              {headers.map((header, index) => (
                <td
                  role='cell'
                  key={`${header}-content`}
                  data-testid={`${header}-content`}
                  data-cell={header}
                >
                  {index === 0 ? (
                    <>
                      <Text font='body'>
                        {`${item.label}${item.description ? `: ${item.description}` : ''}`}
                      </Text>
                      {!!formErrors?.[item.id] && (
                        <p id={`error-message-${item.id}`}>{formErrors?.[item.id]}</p>
                      )}
                    </>
                  ) : (
                    renderContent(item, header, itemIndex)
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>

      <Container
        p='2rem 1rem'
        mt='auto'
        width='100%'
        alignItems='center'
        justifyContent='space-between'
        flexDirection='row-reverse'
      >
        <Button type='submit' value={formData.nextBtn || 'PRÓXIMO >'} onClick={handleSubmit} />
        <Button
          onClick={goToPreviousStep}
          variant='outline'
          value={formData.backBtn || '< ANTERIOR'}
        />
      </Container>
    </StyledForm>
  )
}

export default Table
