import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/Modal'
import Button from '../../../../components/button'
import Text from '../../../../components/text'

type TErrorModal = {
  errorMessage?: string
  handleCloseModal: () => void
}

const ErrorModal: React.FC<TErrorModal> = ({ handleCloseModal, errorMessage }) => {
  return errorMessage ? (
    <Modal id='fileError' onModalClose={handleCloseModal}>
      <ModalHeader title='Algo deu errado com o upload do seu arquivo' />
      <ModalBody>
        <Text font='body' textAlign='center' fontWeight='700'>
          {errorMessage}
        </Text>
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Button value='OK' variant='outline' onClick={handleCloseModal} />
        </div>
      </ModalFooter>
    </Modal>
  ) : null
}

export default ErrorModal
