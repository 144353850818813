import Text from '../text'
import { AddLinkInputContainer } from './style'

type TAddLinkInput = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AddLinkInput: React.FC<TAddLinkInput> = ({ value, onChange }) => {
  return (
    <AddLinkInputContainer>
      <Text font='body' fontWeight={600}>
        https:/
      </Text>
      <input
        value={value}
        onChange={onChange}
        type='text'
        aria-label='Insira um link do seu portifólio e/ou currículo'
        placeholder='Inserir aqui o link'
      />
    </AddLinkInputContainer>
  )
}

export default AddLinkInput
