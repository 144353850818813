export const phoneFormatter = (value: string) => {
  if (value?.length > 14) {
    return value
      ?.replace(/\D/g, '')
      ?.replace(/(\d{2})(\d)/, '($1) $2')
      ?.replace(/(\d{5})(\d)/, '$1-$2')
      ?.replace(/(-\d{4})\d+?$/, '$1')
  }

  return value
    ?.replace(/\D/g, '')
    ?.replace(/(\d{2})(\d)/, '($1) $2')
    ?.replace(/(\d{5})(\d)/, '$1-$2')
    ?.replace(/(-\d{4})\d+?$/, '$1')
}

export const cepFormatter = (cep: string) => {
  return cep
    ?.replace(/\D/g, '')
    ?.replace(/(\d{5})(\d)/, '$1-$2')
    ?.replace(/(-\d{3})\d+?$/, '$1')
}

export const onlyNumbers = (value: string) => `${value}`?.replace(/\D/g, '')

export const cleanObjectForSubmit = (oldObject: Record<string, unknown>) => {
  return Object.entries(oldObject).reduce(
    (acc, [key, value]) => {
      if (typeof value !== 'object') return { ...acc, [key]: value }

      const valueAsObject = value as Record<string, unknown>
      if (Object.keys(valueAsObject).length > 1) return { ...acc, [key]: value }

      const duplicatedKey = valueAsObject[key]
      if (duplicatedKey) return { ...acc, [key]: duplicatedKey }

      return { ...acc, [key]: value }
    },
    {} as Record<string, unknown>
  )
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>
