import { StyledCheckboxContainer } from './styles'
import { CheckboxProps } from './types'

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  checked,
  onChange,
  autoFocus,
  ...spaceProps
}) => {
  return (
    <StyledCheckboxContainer {...spaceProps}>
      <input id={id} checked={checked} type='checkbox' onChange={onChange} autoFocus={autoFocus} />
      <label htmlFor={id}>{label}</label>
    </StyledCheckboxContainer>
  )
}
