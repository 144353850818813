import React, { useMemo } from 'react'
import { StyledContainer } from './styles'
import { ContainerProps } from './types'

const Container: React.FC<ContainerProps> = props => {
  const tag = useMemo(() => {
    if (props.main) return 'main'
    if (props.onClick) return 'button'

    return 'div'
  }, [props.main, props.onClick])

  return (
    <StyledContainer
      as={tag}
      {...(tag === 'button' && {
        type: 'button'
      })}
      {...props}
    >
      {props.children}
    </StyledContainer>
  )
}

export default Container
