import React from 'react'
import FormComponentsStrategy from '../../../utils/strategy/formComponetsStrategy'

import { TLayoutProps } from '../../../types/types'
import { TSimpleForm } from '../../../types/PossibleSteps'
import { TBasedOnAnswer, TChoiseGroup } from '../../../types/AdditionalTypes'

const shouldHideField = (
  basedOnAnswer: TBasedOnAnswer,
  values: Record<string, string> | Record<string, string[]>
) => {
  const expectedField = basedOnAnswer.field

  if (typeof values[expectedField] === 'string') {
    return values[expectedField] !== basedOnAnswer.value
  }

  return !((values[expectedField] as string[]) || []).includes(basedOnAnswer.value)
}

const FormItems: React.FC<TLayoutProps<TSimpleForm>> = ({ stepInfo, formikProps }) => {
  const {
    content: { fields, inputVariation }
  } = stepInfo

  const { errors, touched, values, handleBlur, submitCount, handleChange } = formikProps

  return (
    <>
      {fields.map((item, index) => {
        if (item.basedOnAnswer) {
          const shouldHide = shouldHideField(item.basedOnAnswer, values as Record<string, string>)
          if (shouldHide) return null
        }

        return FormComponentsStrategy[item.type](item, {
          autoFocus: index === 0,
          formikProps,
          onBlur: handleBlur,
          value: values[item.id],
          onChange: handleChange,
          selectedItems: values[item.id],
          errorMessage: (touched[item.id] || submitCount > 0) && errors[item.id],
          getErrorMessage: (id: string) => (touched[id] || submitCount > 0) && errors[id],
          variant: (item as TChoiseGroup)['variant'] || inputVariation || 'primary'
        })
      })}
    </>
  )
}

export default React.memo(FormItems)
