import styled from 'styled-components'

export const StyledCardLoginContainer = styled.div`
  width: 90%;
  display: flex;
  padding: 50px;
  margin: auto;
  max-width: 469px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 100px 0px;
  ${props => props.theme.shadow[20]}
  background: linear-gradient(
    180deg,
    ${props => props.theme.colors.primary} 0%,
    ${props => props.theme.colors.primaryLight} 100%
  );
`

export const StyledLogoContainer = styled.div`
  top: -35px;
  padding: 8px 12px;
  position: absolute;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.white};
  ${props => props.theme.shadow[20]}

  img {
    width: 180px;
    height: 50px;
  }
`
