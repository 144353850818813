import React, { useContext, useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { LayoutContextProps } from './types'

export const LayoutContext = React.createContext<LayoutContextProps | null>(null)

interface Props {
  children: React.ReactNode
}

const LayoutContextProvider = ({ children }: Props) => {
  const theme = useTheme()
  const [currentWindowSize, setCurrentWindowSize] = useState<'sm' | 'md' | 'lg'>('lg')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const getCurrentSize = () => {
      if (window.innerWidth <= theme.breakpoint.sm) return 'sm'
      if (window.innerWidth <= theme.breakpoint.md) return 'md'

      return 'lg'
    }

    function handleResize() {
      const currentWindowSize = getCurrentSize()
      setCurrentWindowSize(currentWindowSize)
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [theme])

  return (
    <LayoutContext.Provider value={{ currentWindowSize, isMobile }}>
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => {
  const context = useContext(LayoutContext)

  if (context) return context

  throw new Error('useLayoutContext must be used within a LayoutContextProvider.')
}

export default LayoutContextProvider
