import styled from 'styled-components'
import { space } from 'styled-system'

export const StyledTextareaLabel = styled.label<{ variant?: 'primary' | 'secondary' }>`
  color: ${props => (props.variant === 'secondary' ? '#000' : '#fff')};
  display: flex;
  flex-direction: column;

  line-height: 38px;
  font-size: 1.125rem;
  font-weight: 800;
  min-height: 180px;
  height: 96%;

  ${props => props.theme.fonts.body}
  font-weight: 800;
  position: relative;

  p[id^='error-message'] {
    gap: 5px;
    font-size: 14px;
    font-weight: 800;
  }
`

export const StyledTextarea = styled.textarea<Record<string, unknown>>`
  flex-grow: 1;
  ${props => props.theme.fonts.body}
  color: ${props => (props.variant === 'secondary' ? '#000' : '#fff')};
  background-color: transparent;
  border: 0px;
  height: 100%;
  font-weight: 300;
  border: 1px solid transparent;
  font-size: 1rem;
  ${space}

  &::placeholder {
    color: ${props => (props.variant === 'secondary' ? '#000' : '#fff')};
    opacity: 0.7;
    font-weight: 500;
  }

  &:focus {
    outline: none;
    border: 1px solid ${props => (props.variant === 'secondary' ? '#000' : '#fff')};
    border-radius: 3px;
  }
`
