import { Fragment } from 'react'
import { StyledBreadcrumbOl, StyledLi, StyledSeparator, StyledStepsContainer } from './styles'
import { BreadcrumbProps } from './types'
import { useLayoutContext } from '../../context/LayoutContext'
import Text from '../text'

const Breadcrumb: React.FC<BreadcrumbProps> = props => {
  const { size, currentStep, onClickStep, lastVisitedStep, ...spaceProps } = props
  const { isMobile } = useLayoutContext()

  if (isMobile) return <Text font='body' m={2}>{`Passo ${currentStep + 1} de ${size}`}</Text>

  return (
    <StyledStepsContainer {...spaceProps}>
      <nav aria-label='breadcrumbs'>
        <StyledBreadcrumbOl>
          {size <= 16 &&
            Array.from(Array(size).keys()).map((item, index) => (
              <Fragment key={index}>
                <StyledLi
                  aria-current={item === currentStep}
                  isActive={index <= lastVisitedStep || index <= currentStep}
                >
                  <button
                    disabled={index > lastVisitedStep}
                    type='button'
                    onClick={() => onClickStep(index)}
                  >
                    {item + 1}
                  </button>
                </StyledLi>
                {index !== size - 1 && (
                  <StyledSeparator
                    isActive={index < lastVisitedStep || index < currentStep}
                    aria-hidden='true'
                  />
                )}
              </Fragment>
            ))}
        </StyledBreadcrumbOl>
      </nav>
    </StyledStepsContainer>
  )
}

export default Breadcrumb
