import styled, { css } from 'styled-components'
import { space } from 'styled-system'

export const StyledInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  ${space}

  p[id^="error-message"] {
    width: 100%;
    margin: 0;
    padding-left: 49px;
    position: absolute;
    top: 100%;
    font-size: 13px;
    ${props => props.theme.fonts.body}
    color: ${props => props.theme.colors.error};
    font-weight: 500;
    line-height: 1;
  }
`

export const StyledIcon = styled.div<{ icon: string }>`
  background: ${({ icon }) => icon};
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 1rem;
`

export const StyledInput = styled.input<{ variant?: 'primary' | 'secondary' }>`
  flex: 1;
  border: none;
  padding: 8px 0;
  font-size: 1.125rem;
  font-weight: 700;
  transition: all 300ms;
  background-color: transparent;
  ${props => props.theme.fonts.body}
  line-height: 1;

  @media screen and (max-width: 767px) {
    &[type='date'][value='']:before {
      content: attr(placeholder);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  /* Firefox */
  &[type='number'],
  &[type='date'] {
    -moz-appearance: textfield;
  }

  ${({ variant, theme }) => css`
    color: ${variant === 'secondary' ? theme.colors.white : theme.colors.black};
    border-bottom: 1.5px solid ${variant === 'secondary' ? theme.colors.white : '#000'};
  `}

  &::placeholder {
    color: ${props =>
      props.variant === 'secondary' ? props.theme.colors.white : props.theme.colors.black};
    opacity: 1;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.secondary};
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`
