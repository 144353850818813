import AvatarLogo from '../../../../components/AvatarLogo'
import { ImageFromStep, MessageLayout, MessageLayoutWrapper } from '../styles'
import { ReactComponent as MessageIcon } from '../../../../assets/images/Message.svg'
import { TLayoutProps } from '../../../../types/types'
import { TSimpleForm } from '../../../../types/PossibleSteps'
import { useLayoutContext } from '../../../../context/LayoutContext'
import FormItems from '../FormItems'

const Message: React.FC<TLayoutProps<TSimpleForm>> = ({ stepInfo, formikProps }) => {
  const { isMobile } = useLayoutContext()
  const {
    logoFromChooseAvatar,
    logo,
    logoAlt,
    content: { textColor, backgroundColor }
  } = stepInfo
  const showName =
    logoFromChooseAvatar?.showName !== undefined ? logoFromChooseAvatar?.showName : true

  return (
    <MessageLayoutWrapper>
      {logoFromChooseAvatar ? (
        <AvatarLogo stepInfo={stepInfo} size={isMobile ? '100px' : '200px'} showName={showName} />
      ) : (
        <ImageFromStep src={logo} alt={logoAlt} />
      )}
      <MessageLayout backgroundColor={backgroundColor} textColor={textColor}>
        <FormItems formikProps={formikProps} stepInfo={stepInfo} />
        <MessageIcon fill={backgroundColor} />
      </MessageLayout>
    </MessageLayoutWrapper>
  )
}

export default Message
