import { buildYup } from 'schema-to-yup'

export const DEFAULT_ERROR_MSG = 'Esse campo é obrigatório'

type TYupSchema = {
  type: 'object'
  properties: Record<string, unknown>
}

type TYupConfig = {
  errMessages: Record<string, Record<string, string>>
  logging?: boolean
}

type TYupFactoryProp = {
  id: string
  yup: Record<string, unknown>
}

const editYupSchema = (schema: TYupSchema, config: TYupConfig, item: TYupFactoryProp) => {
  const { id, yup } = item
  schema.properties[id] = { ...yup }

  config.errMessages[id] = {
    required: (yup.requiredErrorMsg as string) || DEFAULT_ERROR_MSG
  }

  if (yup.errMessages) {
    Object.entries(yup.errMessages).map(([key, value]) => {
      config.errMessages[id][key] = value
    })
  }
}

export const yupValidationFactory = (fields: TYupFactoryProp[]) => {
  const schema: TYupSchema = { type: 'object', properties: {} }
  const config: TYupConfig = { errMessages: {} }

  fields.map(item => {
    editYupSchema(schema, config, item)

    if (item.id === 'cep') {
      const yup = {
        type: 'string',
        required: true
      }

      editYupSchema(schema, config, { id: 'state', yup })
      editYupSchema(schema, config, { id: 'city', yup })
    }
  })

  return buildYup(schema, config)
}
