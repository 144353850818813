import {
  TComplexForm,
  TUploadFiles,
  TInformativeStep,
  TSimpleForm,
  TTable
} from '../../types/PossibleSteps'
import { TContent, TStep } from '../../types/types'
import ComplexForm from '../../pages/Steps/ComplexForm'
import InformationStep from '../../pages/Steps/InformationStep'
import SimpleForm from '../../pages/Steps/SimpleStep'
import Table from '../../pages/Steps/Table'
import UploadFile from '../../pages/Steps/UploadFile'

class StepStrategy {
  static info(stepInfo: TStep<TContent>) {
    return <InformationStep stepInfo={stepInfo as TStep<TInformativeStep>} />
  }

  static simpleForm(stepInfo: TStep<TContent>) {
    return <SimpleForm stepInfo={stepInfo as TStep<TSimpleForm>} />
  }

  static complexForm(stepInfo: TStep<TContent>) {
    return <ComplexForm stepInfo={stepInfo as TStep<TComplexForm>} />
  }

  static files(stepInfo: TStep<TContent>) {
    return <UploadFile stepInfo={stepInfo as TStep<TUploadFiles>} />
  }

  static table(stepInfo: TStep<TContent>) {
    return <Table stepInfo={stepInfo as TStep<TTable>} />
  }
}

export default StepStrategy
