// https://medium.com/@seif_ghezala/how-to-create-an-accessible-react-modal-5b87e6a27503
// https://codesandbox.io/s/oqvw5lpz35?from-embed=&file=/src/index.js

import { createContext, useContext, useEffect, createRef } from 'react'
import { createPortal } from 'react-dom'
import { TModalContext, TModalHeaderProps, TModalProps, TModalBody } from './types'
import { ModalContent, ModalOverlay, SModalFooter, SModalHeader } from './styles'
import Icon from '../Icon'
import Text from '../text'

const ModalContext = createContext<TModalContext | null>(null)

export const Modal: React.FC<TModalProps> = ({ children, onModalClose, id }) => {
  useEffect(() => {
    const keyListener = (event: KeyboardEvent | MouseEvent) => {
      const listener = keyListenersMap.get((event as KeyboardEvent).keyCode)
      return listener && listener(event as MouseEvent)
    }
    document.addEventListener('keydown', keyListener)

    return () => document.removeEventListener('keydown', keyListener)
  })

  const modalRef = createRef<HTMLDivElement>()
  const handleTabKey = (e: MouseEvent) => {
    if (!modalRef?.current) return null

    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    )
    const firstElement = focusableModalElements[0] as HTMLElement
    const lastElement = focusableModalElements[focusableModalElements.length - 1] as HTMLElement

    if (!e.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (e.shiftKey && document.activeElement !== lastElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const keyListenersMap = new Map([
    [27, onModalClose],
    [9, handleTabKey]
  ])

  return createPortal(
    <ModalOverlay
      role='dialog'
      aria-modal='true'
      aria-labelledby={`${id}-title`}
      onClick={onModalClose}
    >
      <ModalContent ref={modalRef} onClick={e => e.stopPropagation()}>
        <ModalContext.Provider value={{ onModalClose, id }}>{children}</ModalContext.Provider>
      </ModalContent>
    </ModalOverlay>,
    document.body
  )
}

export const ModalHeader: React.FC<TModalHeaderProps> = ({ title }) => {
  const { onModalClose, id } = useContext(ModalContext) || {}

  return (
    <SModalHeader id={`${id}-title`}>
      <Text font='title' as='h2' fontSize='1.3rem'>
        {title}
      </Text>
      <button title='close modal' onClick={onModalClose}>
        <Icon name='close' size={25} />
      </button>
    </SModalHeader>
  )
}

export const ModalBody: React.FC<TModalBody> = props => {
  return <div className='modal-body'>{props.children}</div>
}

export const ModalFooter: React.FC<TModalBody> = props => {
  return <SModalFooter>{props.children}</SModalFooter>
}

export default Modal
