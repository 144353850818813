import { SwiperSlide, Swiper } from 'swiper/react'

import Text from '../../../../../components/text'
import Container from '../../../../../components/container'
import { CardItem } from '../../../../../components/CardItem'
import AccordionItem from '../../../../../components/AccordionGroup'

import { useFormContext } from '../../../../../context/FormContext'
import { TComplexForm } from '../../../../../types/PossibleSteps'
import { TLayoutProps } from '../../../../../types/types'
import { TAvatar, TOption } from '../../../../../types/AdditionalTypes'
import { DEFAULT_ERROR_MSG } from '../../../../../utils/schemaToYup'
import { splitArray } from '../../../../../utils/arrayManipulation'
import { GridContainer } from '../../styles'
import { getIsSelected } from '../../utils'
import { useLayoutContext } from '../../../../../context/LayoutContext'

export const CardItemSliders: React.FC<TLayoutProps<TComplexForm>> = ({
  stepInfo,
  formikProps
}) => {
  const { formContent } = useFormContext()
  const { isMobile } = useLayoutContext()

  const {
    id,
    content: {
      type,
      showDescription,
      fields,
      carouselOptions,
      maxItemsPerPage,
      maxItemsPerPageMobile,
      maxRows
    }
  } = stepInfo
  const maxItemsPerPageLayout = (isMobile && maxItemsPerPageMobile) || maxItemsPerPage
  const initialSlide = (formContent[id] as TAvatar)?.slideIndex || 0
  const inputType = type === 'single' ? 'radio' : 'checkbox'

  const pagesArray = splitArray(fields as TOption[], maxItemsPerPageLayout)
  const columns = maxItemsPerPageLayout && maxRows ? Math.floor(maxItemsPerPageLayout / maxRows) : 1

  const { errors, values, touched, submitCount, setFieldValue } = formikProps || {}

  const selectedItem = values[id] as TOption
  const errorMessage = (touched[id] || submitCount > 0) && errors[id]
  const relativeLabel = fields.some(item => item.label.length > 40)
  const minHeight = relativeLabel ? '150px' : '110px'
  const hasDescription = !!pagesArray[0][0].description

  const handleClick = (slideIndex: number, item: TOption) => {
    if (type === 'single') return setFieldValue(id, { ...item, slideIndex })

    const prevValues = values[id] || []
    const allLabels = (prevValues as TOption[])?.map(selected => selected.label)
    const addNewItem = !allLabels?.includes(item.label)

    if (addNewItem) return setFieldValue(id, [...prevValues, { ...item, slideIndex }])

    const itemToRemove = allLabels.indexOf(item.label)
    prevValues.splice(itemToRemove, 1)
    return setFieldValue(id, prevValues)
  }

  return (
    <Container
      mx='auto'
      height='100%'
      width='clamp(320px, 720px, 100vw)'
      alignItems='flex-start'
      alignContent='center'
      flexDirection='column'
      justifyContent='space-around'
      mt='1rem'
      p='1rem'
    >
      <Swiper
        id={id}
        initialSlide={initialSlide}
        {...carouselOptions}
        data-testid={(maxRows || 1) === 1 ? 'align-center' : ''}
      >
        {pagesArray.map((pageItems, index) => (
          <SwiperSlide key={'slide_' + index}>
            <GridContainer
              gridTemplateRows={`repeat(auto-fill, minmax(${minHeight}, 1fr))`}
              gridTemplateColumns={`repeat(${columns}, 1fr)`}
            >
              {pageItems.map((item, indexItem) => {
                const isSelected = getIsSelected(
                  { type } as Pick<TComplexForm, 'type'>,
                  selectedItem,
                  item
                )

                return (
                  <CardItem
                    className='grid-item'
                    key={item.id}
                    relativeLabel={relativeLabel}
                    id={item.id}
                    inputType={inputType}
                    isSelected={isSelected}
                    iconName={item.iconName}
                    icon={item.icon}
                    label={item.label}
                    onClick={(slideIndex: number) => handleClick(slideIndex, item)}
                    groupId={id}
                    autoFocus={!isMobile && indexItem === 0 && index === 0}
                  />
                )
              })}
            </GridContainer>
          </SwiperSlide>
        ))}
      </Swiper>
      {(errorMessage || errorMessage?.id) && (
        <p style={{ position: 'relative' }} id={`error-message-${id}`}>
          {DEFAULT_ERROR_MSG}
        </p>
      )}
      {type === 'multiple' && Array.isArray(selectedItem) && showDescription && (
        <AccordionItem items={selectedItem} />
      )}
      {hasDescription && (
        <Container
          mx='auto'
          mt='1rem'
          alignItems='center'
          flexDirection='column'
          width='clamp(320px, 720px, 95vw)'
          maxWidth='100%'
          minHeight='150px'
          pt={3}
        >
          {selectedItem?.description ? (
            <Container alignItems='center'>
              <Text textAlign='center' font='body' fontSize='1.125rem' lineHeight='28px'>
                {selectedItem?.description}
              </Text>
            </Container>
          ) : (
            <></>
          )}
        </Container>
      )}
    </Container>
  )
}
