import Modal, { ModalFooter, ModalHeader } from '../../../../components/Modal'
import Button from '../../../../components/button'

type TDeleteFileConfirmationModal = {
  fileToDelete?: string
  handleCloseModal: () => void
  handleDeleteModal: () => void
}

const DeleteFileConfirmationModal: React.FC<TDeleteFileConfirmationModal> = ({
  handleCloseModal,
  handleDeleteModal,
  fileToDelete
}) => {
  return fileToDelete ? (
    <Modal id='fileError' onModalClose={handleCloseModal}>
      <ModalHeader title={`Tem certeza que deseja remover o arquivo ${fileToDelete}?`} />
      <ModalFooter>
        <Button value='Cancelar' variant='outline' onClick={handleCloseModal} />
        <Button value='Remover' onClick={handleDeleteModal} />
      </ModalFooter>
    </Modal>
  ) : null
}

export default DeleteFileConfirmationModal
