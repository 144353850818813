import React from 'react'
import { useFormContext } from '../../context/FormContext'
import { TContent, TStep } from '../../types/types'
import { getAvatarVariationUrl } from '../../utils/avatar'
import { AvatarLogoContainer, AvatarLogoWrapper } from './styles'
import Text from '../text'
import { useTheme } from 'styled-components'

type TAvatarLogo = {
  stepInfo: TStep<TContent>
  altInfo?: string
  size?: string
  textColor?: string
  label?: string
  showName?: boolean
}

const AvatarLogo: React.FC<TAvatarLogo> = ({
  stepInfo,
  altInfo,
  size,
  showName,
  label,
  textColor
}) => {
  const theme = useTheme()
  const { avatarInfo } = useFormContext()

  const { logoFromChooseAvatar } = stepInfo
  const altMsg = logoFromChooseAvatar?.avatarVariationAlt || altInfo

  const onError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null
    currentTarget.src = avatarInfo?.avatar || ''
  }

  return (
    <AvatarLogoWrapper size={size}>
      <AvatarLogoContainer bg={avatarInfo?.bg} size={size}>
        <img
          src={getAvatarVariationUrl(avatarInfo, logoFromChooseAvatar?.avatarVariation)}
          onError={onError}
          alt={altMsg}
        />
      </AvatarLogoContainer>
      {showName && (
        <Text
          value={label || avatarInfo?.label}
          fontSize='1.125rem'
          lineHeight='23px'
          textAlign='center'
          font='body2'
          color={textColor || theme.colors.black}
        />
      )}
    </AvatarLogoWrapper>
  )
}

export default AvatarLogo
