import AvatarLogo from '../../../../components/AvatarLogo'
import { ImageFromStep, ColorLayout, ColorLayoutWrapper } from '../styles'
import { TLayoutProps } from '../../../../types/types'
import { TSimpleForm } from '../../../../types/PossibleSteps'
import FormItems from '../FormItems'

const Color: React.FC<TLayoutProps<TSimpleForm>> = ({ stepInfo, formikProps }) => {
  const {
    logoFromChooseAvatar,
    logo,
    logoAlt,
    content: { backgroundColor, textColor, variant }
  } = stepInfo
  const showName =
    logoFromChooseAvatar?.showName !== undefined ? logoFromChooseAvatar?.showName : true
  return (
    <ColorLayoutWrapper variant={variant}>
      {logoFromChooseAvatar ? (
        <AvatarLogo stepInfo={stepInfo} showName={showName} />
      ) : (
        <ImageFromStep src={logo} alt={logoAlt} />
      )}
      <ColorLayout backgroundColor={backgroundColor} textColor={textColor}>
        <FormItems formikProps={formikProps} stepInfo={stepInfo} />
      </ColorLayout>
    </ColorLayoutWrapper>
  )
}

export default Color
