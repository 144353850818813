import styled from 'styled-components'

export const StyledChooseButtonsFieldset = styled.fieldset<{
  variant?: 'primary' | 'secondary' | 'white'
  layout?: 'row' | 'column'
}>`
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 2;
  position: relative;

  legend {
    ${props => props.theme.fonts.body};
    font-weight: 700;
    margin-bottom: 0.3rem;
    color: ${props =>
      props.variant === 'primary' ? props.theme.colors.black : props.theme.colors.white};
  }

  div {
    gap: 0.5rem;
  }

  > div {
    gap: ${({ layout }) => (layout === 'row' ? '1.5rem' : '0.5rem')};
  }
`
