import styled, { css } from 'styled-components'
import { space } from 'styled-system'

export const StyledCheckboxContainer = styled.div<{ variant?: 'primary' | 'secondary' }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  input {
    opacity: 0;
    width: 24px;
    height: 24px;
    margin: 0;

    + label {
      padding-left: 8px;
      font-size: 16px;
      line-height: 16px;
      cursor: pointer;
      color: ${props =>
        props.variant === 'secondary' ? props.theme.colors.black : props.theme.colors.white};
      ${props => props.theme.fonts.body}

      &::before {
        content: '';
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        width: 14px;
        height: 14px;
        position: absolute;
        border-radius: 5px;
        border: 2px solid ${props => props.theme.colors.white};

        ${props =>
          props.variant === 'secondary' &&
          css`
            border: 2px solid ${props.theme.colors.gray[200]};
            background-color: ${props.theme.colors.gray[200]};
          `}
      }

      &::after {
        content: '';
        opacity: 0;
        position: absolute;
        top: 46%;
        left: 8px;
        width: 3px;
        height: 8px;
        border: 2px solid ${props => props.theme.colors.white};
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) translate(0, -50%);
      }
    }

    &:checked {
      + label {
        &::before {
          ${props =>
            props.variant === 'secondary' &&
            css`
              background-color: ${props.theme.colors.primary};
              border: 2px solid ${props.theme.colors.primary};
            `}
        }
        &::after {
          opacity: 1;
        }
      }
    }

    &:focus {
      + label {
        &::before {
          border: 2px solid
            ${props =>
              props.variant === 'secondary'
                ? props.theme.colors.primary
                : props.theme.colors.white};
        }
      }
    }
  }

  ${space}
`
