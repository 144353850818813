import styled, { css } from 'styled-components'
import { TFont } from '../../types/AdditionalTypes'

export const StyledHeaderContainer = styled.header<{
  background?: string
  font?: TFont
  maxHeight?: string
}>`
  padding: 12px 24px;
  height: ${props => props.maxHeight || '60px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.background || `${props.theme.colors.white}cc`};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  button {
    border: none;
    display: flex;
    background: none;
    cursor: pointer;
  }

  ${({ font }) =>
    font &&
    css`
      font-family: ${font['font-family']};
      font-size: ${font['font-size']};
      font-weight: ${font['font-weight']};
      line-height: ${font['line-height']};
      color: ${font?.color};
    `}

  @media screen and (max-width: 768px) {
    padding: 6px;

    img {
      width: 90px;
      height: auto;
    }

    span {
      font-size: 14px;
    }
  }
`
