import { StyledTextarea, StyledTextareaLabel } from './styles'
import { TextareaProps } from './types'

export const Textarea: React.FC<TextareaProps> = props => {
  return (
    <StyledTextareaLabel variant={props.variant}>
      {props.label}
      <StyledTextarea
        aria-invalid={!!props.errorMessage}
        {...(props.errorMessage && {
          'aria-describedby': `error-message-${props.id}`
        })}
        {...props}
      />
      {props.errorMessage && <p id={`error-message-${props.id}`}>{props.errorMessage}</p>}
    </StyledTextareaLabel>
  )
}
