import { useTheme } from 'styled-components'
import { useSwiper } from 'swiper/react'
import Icon from '../Icon'
import { StyledSelectItemContainer, StyledSelectItemLabel, StyledSelectItemWrapper } from './styles'
import { CardItemProps } from './types'

export const CardItem: React.FC<CardItemProps> = ({
  id,
  icon,
  label,
  groupId,
  iconName,
  className,
  inputType,
  isSelected,
  relativeLabel,
  autoFocus,
  onClick,
  ...otherProps
}) => {
  const theme = useTheme()
  const swiper = useSwiper()

  const onChange = () => {
    swiper.slideTo(swiper.clickedIndex)
    onClick(swiper.clickedIndex)
  }

  return (
    <StyledSelectItemWrapper>
      <StyledSelectItemContainer {...otherProps} isSelected={isSelected} className={className}>
        <input
          autoFocus={autoFocus}
          name={groupId}
          id={id}
          checked={isSelected}
          type={inputType}
          onChange={onChange}
          value={label}
        />
        {iconName && (
          <Icon
            size={60}
            name={iconName}
            color={isSelected ? theme.colors.secondary : theme.colors.gray[800]}
          />
        )}
        {icon && <img src={icon} alt='' />}
      </StyledSelectItemContainer>
      <StyledSelectItemLabel
        data-testid='label'
        isSelected={isSelected}
        label={label}
        relativeLabel={relativeLabel}
      >
        {label}
      </StyledSelectItemLabel>
    </StyledSelectItemWrapper>
  )
}
