import styled from 'styled-components'

export const StyledError = styled.main`
  margin: auto;
  display: flex;
  gap: 2rem;
  height: 100vh;
  width: 100%;

  justify-content: center;
  align-items: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    border: none;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #408ec5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  img {
    margin: 0 auto;
    width: 200px;
  }
`
