import React from 'react'
import { FilesPreviewContainer } from './styles'
import Icon from '../../../components/Icon'
import { convertBytes } from './utils'
import { useTheme } from 'styled-components'
import { TCustomFile } from '../../../context/FormContext/types'

type TFilesPreview = {
  files: TCustomFile[]
  setFileToDelete: (fileName: string) => void
}

const FilesPreview: React.FC<TFilesPreview> = ({ files, setFileToDelete }) => {
  const theme = useTheme()
  return files.length ? (
    <FilesPreviewContainer>
      <ul>
        {files?.map(file => (
          <li key={file.name}>
            <div data-testid='fileName'>
              <a target='_blank' rel='noreferrer' href={file.url}>
                {file.name}
              </a>
              ({convertBytes(file.size)})
            </div>
            <button
              type='button'
              aria-label='Excluir arquivo'
              onClick={() => setFileToDelete(file.name)}
            >
              <Icon name='delete' size={23} color={theme.colors.error} />
            </button>
          </li>
        ))}
      </ul>
    </FilesPreviewContainer>
  ) : null
}

export default FilesPreview
