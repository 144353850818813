import React from 'react'
import { StyledChooseButtonsContainer } from './styles'
import { TTRadioOrCheckboxProps } from './types'

const RadioOrCheckbox: React.FC<TTRadioOrCheckboxProps> = ({
  item,
  id,
  layout,
  variant,
  autoFocus,
  isChecked,
  inputType,
  onChange,
  hideLabel,
  isRequired = false,
  ...props
}) => {
  const handleInvalid = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    const input = event.target as HTMLInputElement
    input.closest('form')?.querySelectorAll('input')[0]?.focus()
  }

  return (
    <StyledChooseButtonsContainer
      key={item.id}
      type={inputType}
      variant={variant}
      layout={layout}
      hideLabel={hideLabel}
    >
      <input
        name={id}
        id={item.id}
        type={inputType}
        value={item.label}
        checked={isChecked}
        autoFocus={autoFocus}
        onChange={onChange}
        required={isRequired}
        aria-invalid={props['aria-invalid']}
        onInvalid={handleInvalid}
        {...(props['aria-invalid'] && {
          'aria-describedby': `error-message-${id}`
        })}
      />
      <label htmlFor={item.id}>{hideLabel ? '' : item.label}</label>
    </StyledChooseButtonsContainer>
  )
}

export default RadioOrCheckbox
