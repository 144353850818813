import { createBrowserRouter } from 'react-router-dom'
import Error from '../components/Error'
import Layout from '../components/layout'
import Form from '../pages/Form'
import Login from '../pages/login'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Layout outlet={<Error />} />,
    children: [
      {
        index: true,
        path: 'login/:formId',
        element: <Login />
      },
      {
        path: 'matchmaking/:formId',
        element: <Form />
      },
      {
        path: '/',
        element: (
          <Error description='O link não parece certo, por favor entre em contato!' hideButton />
        )
      }
    ]
  }
])

export default router
