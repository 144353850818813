import { ReactNode } from 'react-markdown/lib/ast-to-react'
import { useTheme } from 'styled-components'
import Container from '../../components/container'
import Text from '../../components/text'
import { TContent, TStep } from '../../types/types'

type TStepProps = {
  children: ReactNode
  stepInfo: TStep<TContent>
}

const Step = ({ children, stepInfo }: TStepProps) => {
  const theme = useTheme()
  const { id, textColor, title, subtitle, content } = stepInfo

  return (
    <Container
      id={id}
      data-testid={content.layout}
      width='100%'
      mx='auto'
      flexDirection='column'
      justifyContent={id === 'final' ? 'center' : 'space-evenly'}
    >
      <Text
        as='h1'
        m={2}
        color={textColor || theme.colors.black}
        font='title'
        textAlign='center'
        value={title}
      />
      {subtitle && (
        <Text
          m={2}
          mt={0}
          color={textColor || theme.colors.black}
          font='body'
          textAlign='center'
          value={subtitle}
        />
      )}
      {children}
    </Container>
  )
}

export default Step
