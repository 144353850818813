import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar {
    width: 7px;
  }
        
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(68, 66, 66, 0.125);
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #7b7979;
  }

  button {
    cursor: pointer;
  }

  body {
    overflow-x: hidden;
  }

  ol {
    padding: 0;
  }

  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (max-width: 320px) {
    h1 {
      font-size: 1.3rem !important;
    }
  }
`

export default GlobalStyle
