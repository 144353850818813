import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import Button from '../../components/button'
import Text from '../../components/text'
import CardLogin from './components/CardLogin'

const Login: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { formId } = useParams()

  const login = () => {
    navigate(`/matchmaking/${formId}`)
  }

  return (
    <CardLogin>
      <Text mb='50px' mt='20px' fontSize='22px' fontWeight={800} color={theme.colors.white}>
        Ferramenta de matchmaking
      </Text>
      {/* <Input
        mb="30px"
        variant="secondary"
        icon="user-profile"
        placeholder="Usuário"
      />
      <Input mb="30px" variant="secondary" icon="lock" placeholder="Senha" /> */}
      {/* <Checkbox mb="30px" id="remind-me" label="Lembrar-me" /> */}
      <Button mt='100px' value='Iniciar cadastro' onClick={login} variant='secondary' />
    </CardLogin>
  )
}

export default Login
