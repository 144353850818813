import { TOption } from '../../types/AdditionalTypes'
import { TContent, TStep } from '../../types/types'
import StepStrategy from '../../utils/strategy/stepStrategy'

const handleBasedOnPrevious = (
  contentOfStep: TStep<TContent>,
  formContent: Record<string, unknown>
) => {
  const contentOfStepClone = JSON.parse(JSON.stringify(contentOfStep)) as TStep<TContent>
  const { contentList, basedOnPreviousQuestion, singleContent } = contentOfStepClone
  if (!basedOnPreviousQuestion || !contentList) return contentOfStepClone

  const stepResponse = formContent[basedOnPreviousQuestion]
  const formattedResponse = !Array.isArray(stepResponse) ? [stepResponse] : stepResponse

  if (singleContent && formattedResponse.length) {
    contentOfStepClone.content = contentList['singleContent']

    return contentOfStepClone
  }

  const stepResponseOption = formContent[basedOnPreviousQuestion] as TOption

  if (stepResponseOption?.nextQuestion) {
    contentOfStepClone.content = contentList[stepResponseOption.nextQuestion]

    return contentOfStepClone
  }

  const { title, description, logoFromChooseAvatar } = contentOfStepClone.content

  contentOfStepClone.title = title || contentOfStepClone.title
  contentOfStepClone.subtitle = ''
  contentOfStepClone.description = description || contentOfStepClone.description
  contentOfStepClone.logoFromChooseAvatar =
    logoFromChooseAvatar || contentOfStepClone.logoFromChooseAvatar
  contentOfStepClone.typeOfQuestion = 'info'

  return contentOfStepClone
}

export const getFormStep = (
  contentOfStep: TStep<TContent>,
  formContent: Record<string, unknown>
) => {
  const contentOfStepClone = handleBasedOnPrevious(contentOfStep, formContent)

  const formStep = StepStrategy[contentOfStepClone.typeOfQuestion]
  return { formattedStepInfo: contentOfStepClone, children: formStep(contentOfStepClone) }
}
