import Markdown from 'react-markdown'
import { StyledText } from './styles'
import { TextProps } from './types'

const Text: React.FC<TextProps> = ({
  children,
  value,
  font = 'body',
  as,
  className,
  ...otherProps
}) => {
  return (
    <StyledText className={className} font={font} {...otherProps} as={as}>
      <Markdown linkTarget='_blank'>{children || value || ''}</Markdown>
    </StyledText>
  )
}

export default Text
