import { useEffect, useState, ReactNode } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useFormContext } from '../../context/FormContext'
import Header from '../header'
import Loading from '../Loading'
import { StyledLayoutContainer } from './styles'
import { TPallet } from './types'
import { appendFontLink, appendMetaTags } from './utils'
import { useLayoutContext } from '../../context/LayoutContext'

const Layout: React.FC<{ outlet?: ReactNode }> = ({ outlet }) => {
  const location = useLocation()
  const { isMobile } = useLayoutContext()
  const [pallet, setPallet] = useState<TPallet>()
  const {
    backgroundColor,
    backgroundImg,
    backgroundImgMobile,
    backgroundPosition,
    header: {
      style: { maxHeight }
    },
    currentStep,
    steps,
    meta,
    font
  } = useFormContext()

  useEffect(() => {
    const { title, ...metaTags } = meta
    document.title = title

    appendMetaTags(metaTags)
    appendFontLink(font.linkHref)
  }, [font.linkHref, meta])

  useEffect(() => {
    const deskImg = steps[currentStep].backgroundImg || backgroundImg
    const mobileImg = steps[currentStep].backgroundImgMobile || backgroundImgMobile
    const bgColor = steps[currentStep].backgroundColor || backgroundColor || '#fff'
    const bgPosition = steps[currentStep].backgroundPosition || backgroundPosition || 'center'

    setPallet({
      bgColor,
      bgPosition,
      bgImg: isMobile ? mobileImg || deskImg : deskImg
    })
  }, [
    backgroundColor,
    backgroundImg,
    backgroundImgMobile,
    backgroundPosition,
    currentStep,
    isMobile,
    steps
  ])

  return pallet ? (
    <StyledLayoutContainer {...pallet} headerHeight={maxHeight}>
      {location.pathname !== '/' && <Header />}
      {outlet || <Outlet />}
    </StyledLayoutContainer>
  ) : (
    <Loading fullPage />
  )
}

export default Layout
