import { StyledHeaderContainer } from './styles'
import { useFormContext } from '../../context/FormContext'

const Header: React.FC = () => {
  const {
    clickOnLogo,
    header: {
      logo,
      imgWidth,
      imgHeight,
      rightText,
      style: { font, background, maxHeight }
    }
  } = useFormContext()

  return (
    <StyledHeaderContainer background={background} font={font} maxHeight={maxHeight}>
      <button type='button' onClick={clickOnLogo}>
        <img src={logo} alt='logo' width={imgWidth} height={imgHeight} />
      </button>
      {rightText && <span>| {rightText}</span>}
    </StyledHeaderContainer>
  )
}

export default Header
