import styled, { css } from 'styled-components'
import Container from '../../../components/container'

export const ImageFromStep = styled.img`
  max-width: 200px;
`

export const StyledForm = styled.form<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 0 1rem;
    `}

  p[id^="error-message"] {
    margin: 0;
    width: 100%;
    display: flex;
    gap: 3px;

    z-index: 1;
    bottom: -26px;
    position: absolute;
    align-items: center;

    ${props => props.theme.fonts.body}
    color: ${props => props.theme.colors.error};
    font-size: 12px;
    font-weight: 700;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url('data:image/svg+xml,%3Csvg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 6.41634C11.5041 6.41634 11.9166 6.82884 11.9166 7.33301V10.9997C11.9166 11.5038 11.5041 11.9163 11 11.9163C10.4958 11.9163 10.0833 11.5038 10.0833 10.9997V7.33301C10.0833 6.82884 10.4958 6.41634 11 6.41634ZM10.9908 1.83301C5.93081 1.83301 1.83331 5.93967 1.83331 10.9997C1.83331 16.0597 5.93081 20.1663 10.9908 20.1663C16.06 20.1663 20.1666 16.0597 20.1666 10.9997C20.1666 5.93967 16.06 1.83301 10.9908 1.83301ZM11 18.333C6.94831 18.333 3.66665 15.0513 3.66665 10.9997C3.66665 6.94801 6.94831 3.66634 11 3.66634C15.0516 3.66634 18.3333 6.94801 18.3333 10.9997C18.3333 15.0513 15.0516 18.333 11 18.333ZM11.9166 15.583H10.0833V13.7497H11.9166V15.583Z" fill="%23D73131"/%3E%3C/svg%3E');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
`

export const MessageLayout = styled.div<{ backgroundColor?: string; textColor?: string }>`
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 30px;
  min-height: 255px;
  margin-left: 2rem;
  height: min-content;
  flex-direction: column;
  border-radius: 50px;
  position: relative;

  background: ${props => props.backgroundColor || props.theme.colors.primary};

  option {
    background: #333333c3;
  }

  * {
    p,
    label,
    span,
    legend,
    option {
      color: ${props => props.textColor || props.theme.colors.white} !important;
    }
  }

  svg {
    position: absolute;
    bottom: -14px;
    left: -9px;
    path {
      fill: ${props => props.backgroundColor || props.theme.colors.primary};
    }
  }
`

export const ColorLayoutWrapper = styled.div<{ variant?: 'center' }>`
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 64px;

  > div:first-child {
    margin-right: -20px;
    z-index: 2;
    max-width: 180px;
  }

  > div:last-child {
    margin-top: 5rem;
  }

  ${({ variant }) =>
    variant === 'center' &&
    css`
      > div:last-child {
        margin-top: 0;
        width: clamp(230px, 300px, 100vw);
      }
    `}

  @media screen and (max-width: 768px) {
    padding-top: 0.5rem;
    flex-direction: column;
    align-items: center;

    > div:first-child {
      margin: 0;
    }

    > div:last-child {
      margin: 0;
      margin-top: 1rem;
      width: 100%;
    }
  }
`

export const MessageLayoutWrapper = styled(Container)`
  height: 100%;
  padding-top: 2rem;

  @media screen and (max-width: 768px) {
    padding-top: 0.5rem;
    flex-direction: column;
    align-items: center;

    > div:first-child {
      z-index: 2;
    }

    > div:last-child {
      margin: 0;
      margin-top: 1rem;

      svg {
        top: -14px;
        left: 8%;
        transform: rotate(160deg);
      }
    }
  }
`

export const ColorLayout = styled.div<{ backgroundColor?: string; textColor?: string }>`
  display: flex;
  padding: 35px;
  height: min-content;
  flex-direction: column;
  border-radius: 50px;
  position: relative;
  min-width: 350px;

  @media screen and (max-width: 400px) {
    min-width: 100%;
  }

  background: ${props => props.backgroundColor || props.theme.colors.primary};

  * {
    p,
    label,
    span,
    legend {
      color: ${props => props.textColor || props.theme.colors.white} !important;
    }
  }
`
