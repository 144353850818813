import { DefaultTheme } from 'styled-components'
import { TFont } from '../types/AdditionalTypes'
import { TForm } from '../types/types'

declare module 'styled-components' {
  export interface DefaultTheme {
    breakpoint: {
      sm: number
      md: number
      lg: number
    }
    colors: {
      primary: string
      primaryLight: string
      primaryDark: string
      primaryText: string
      secondary: string
      secondaryLight: string
      secondaryDark: string
      secondaryText: string
      additionalColor: string
      black: string
      white: string
      error: string
      gray: {
        100: string
        200: string
        300: string
        800: string
      }
    }
    shadow: {
      18: string
      20: string
      25: string
    }
    fonts: {
      title: string
      body: string
      body2: string
    }
  }
}

const stringifyFont = (font: TFont) => {
  return JSON.stringify(font).slice(1, -1).replaceAll(',', ';').replaceAll('"', '') + ';'
}

export const getTheme = ({ palette, font }: TForm): DefaultTheme => ({
  breakpoint: {
    sm: 320,
    md: 768,
    lg: 1024
  },
  colors: {
    primary: palette.primary || '#408EC5',
    primaryLight: palette.primaryLight || '#408ec5b3',
    primaryDark: palette.primaryDark || '#34729D',
    primaryText: palette.primaryText || '#fff',
    secondary: palette.secondary || '#705084',
    secondaryDark: palette.secondaryDark || '#58356E',
    secondaryLight: palette.secondaryLight || '#836596',
    secondaryText: palette.secondaryText || '#fff',
    additionalColor: palette.additionalColor || palette.secondary || '#705084',
    white: palette.white || '#ffffff',
    black: palette.black || '#333',
    error: palette.error || '#D73131',
    gray: {
      100: '#F5F5F5',
      200: '#CCCCCC',
      300: '#E2E2E2',
      800: '#838383'
    }
  },
  shadow: {
    18: 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18);',
    20: 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);',
    25: 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'
  },
  fonts: {
    title: stringifyFont(font.title) || "font-family: 'Raleway', sans-serif;",
    body: stringifyFont(font.body) || "font-family: 'Raleway', sans-serif;",
    body2: stringifyFont(font.body2) || "font-family: 'Bungee', cursive;"
  }
})
