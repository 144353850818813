import styled from 'styled-components'

export const ModalOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(45, 55, 72, 0.6);

  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`

export const ModalContent = styled.div`
  position: relative;
  width: clamp(300px, 430px, 90%);
  height: clamp(300px, 300px, 90%);

  display: flex;
  flex-direction: column;
  background-color: #f7f8fa;
  border-radius: 7px;
  border: 1px solid #d8dce3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  padding: 2rem;
`

export const SModalHeader = styled.header`
  display: flex;
  justify-content: center;
  margin-top: auto;

  h2 {
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
  }

  button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
  }
`

export const SModalFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: auto;

  button {
    cursor: pointer;
  }
`
