import { Keyboard, Navigation } from 'swiper'
import { SwiperProps } from 'swiper/react'
import { TComplexForm } from '../../../types/PossibleSteps'
import { TOption } from '../../../types/AdditionalTypes'

export const renderModules = (carouselOptions?: SwiperProps) => {
  const modules = []
  if (carouselOptions?.keyboard) modules.push(Keyboard)
  if (carouselOptions?.navigation) modules.push(Navigation)

  return modules
}

export const getIsSelected = (
  { type }: Pick<TComplexForm, 'type'>,
  selectedItem: TOption | TOption[],
  item: TOption
) => {
  if (type === 'single') return (selectedItem as TOption)?.label === item.label

  const allLabels = (selectedItem as TOption[])?.map(selected => selected.label)

  return allLabels?.includes(item.label) || false
}
