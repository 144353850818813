import styled, { css, DefaultTheme } from 'styled-components'
import { layout, space } from 'styled-system'

const getVariant = ({
  variant,
  theme,
  disabled
}: {
  variant: string
  disabled?: boolean
  theme: DefaultTheme
}) => {
  if (disabled) {
    return css`
      background-color: ${theme.colors.gray[100]};
      border-color: ${theme.colors.gray[100]};
      color: ${theme.colors.gray[200]};
    `
  }
  if (variant === 'outline') {
    return css`
      color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.white};

      &:focus,
      &:hover {
        background-color: ${theme.colors.primaryLight};
        color: ${theme.colors.white};
      }
    `
  }

  if (variant === 'secondary') {
    return css`
      background-color: ${theme.colors.secondary};
      border-color: ${theme.colors.secondary};
      color: ${theme.colors.white};

      &:focus,
      &:hover {
        background-color: ${theme.colors.secondaryDark};
      }
    `
  }

  if (variant === 'link') {
    return css`
      background-color: transparent;
      border: none;
      color: ${theme.colors.black};
      box-shadow: none;
      ${props => props.theme.fonts.body};
      font-weight: 900;
      text-decoration: underline;

      &:focus,
      &:hover {
        text-decoration-color: ${theme.colors.primary};
      }
    `
  }
  return css`
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    &:focus,
    &:hover {
      background-color: ${theme.colors.primaryDark};
    }
  `
}

export const StyledButton = styled.button`
  border: none;
  min-width: 168px;
  padding: 10px 16px;
  border-radius: 50px;
  font-weight: 400;
  line-height: 1;
  font-size: 1.125rem;
  cursor: pointer;
  transition: all 300ms;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px solid;
  ${props => props.theme.fonts.body2}
  line-height: 1;
  ${props => props.theme.shadow[25]}
  ${getVariant}
  ${space}
  ${layout}

  @media screen and (max-width: 768px) {
    padding: 10px;
    min-width: 100px;
    font-size: 0.8rem;
  }
`
