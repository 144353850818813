import styled from 'styled-components'

export const StyledCardMultiLayersContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 5% auto;
  min-height: 360px;

  @media screen and (max-width: 768px) {
    margin: 0;
    margin-top: 70px;
  }
`

export const StyledLayerOne = styled.div`
  position: absolute;
  top: 30px;
  left: 18px;
  transform: rotate(3deg);
  height: 330px;
  width: 800px;
  background-color: #fff;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 42px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 768px) {
    padding: 10px;
    width: 95%;
  }
`

export const StyledLayerTwo = styled(StyledLayerOne)`
  top: 15px;
  left: 20px;
  transform: rotate(1.45deg);

  @media screen and (max-width: 768px) {
    left: 13px;
  }
`

export const StyledLayerMain = styled(StyledLayerOne)`
  padding: 30px;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
`
