import styled from 'styled-components'

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 0 20px;

  caption {
    display: none;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  p[id^='error-message'] {
    position: initial;
  }

  @media screen and (max-width: 768px) {
    th {
      display: none;
    }

    tr {
      padding-bottom: 10px;
    }

    td {
      display: block;
      align-items: center;

      div[type='radio'] {
        text-align: left;
        margin-left: 0.5rem;

        label {
          margin-left: 1.5rem;
        }
      }

      &:first-child {
        background-color: ${({ theme }) => theme.colors.secondary};
        color: ${({ theme }) => theme.colors.white};
      }

      &:last-child {
        padding-bottom: 0.5rem;
      }
    }
  }

  thead {
    z-index: 2;
    position: relative;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;

    th:first-child {
      text-align: left;
      padding-left: 2rem;
      width: 400px;
    }

    p {
      margin: 0;
      font-size: 0.75rem;
    }
  }

  tbody {
    margin-top: -3px;
    display: block;
    background: ${({ theme }) => theme.colors.white};
    max-height: 330px;
    overflow-y: auto;
    border-radius: 0 0 10px 10px;

    tr {
      border-bottom: 1px solid #cbcbcb;
      padding: 1rem;

      &:last-child {
        border: none;
      }

      @media screen and (max-width: 768px) {
        &:first-child td:first-child {
          border-radius: 10px 10px 0 0;
        }
      }
    }

    td {
      text-align: center;
      padding: 0.5rem;

      p {
        margin: 0;
      }

      + td {
        border-left: 1px solid #cbcbcb;

        @media screen and (max-width: 768px) {
          border: none;
        }
      }

      &:first-child {
        text-align: left;
        padding-left: 2rem;
        width: 400px;

        @media screen and (max-width: 768px) {
          width: 100%;
          padding-left: 1rem !important;
        }
      }
    }
  }
`
