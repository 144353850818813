import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from './selection.json'
import { IconProps } from './types'
import { IconContainer } from './style'

const Icon: React.FC<IconProps> = ({ color, name, size, ...spaceProps }) => {
  return (
    <IconContainer {...spaceProps}>
      <IcomoonReact iconSet={iconSet} color={color} size={size} icon={name} />
    </IconContainer>
  )
}

export default Icon
