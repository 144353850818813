import { useSwiper } from 'swiper/react'

import Container from '../../../../../components/container'
import Text from '../../../../../components/text'
import { AvatarContainer, ThumbContainer, ThumbImg } from './styles'
import { CharacterItemProps } from './types'
import { useLayoutContext } from '../../../../../context/LayoutContext'

const CharacterItem = ({ groupId, onClick, characterItem, isSelected }: CharacterItemProps) => {
  const swiper = useSwiper()
  const { isMobile } = useLayoutContext()
  const fontSize = isSelected ? '1.1rem' : '1rem'
  const fontSizeMobile = isSelected ? '0.9rem' : '0.5rem'
  const onChange = () => {
    swiper.slideTo(swiper.clickedIndex)
    onClick(swiper.clickedIndex)
  }

  return (
    <AvatarContainer htmlFor={characterItem.label}>
      <Container flexDirection='column' justifyContent='center' alignItems='center'>
        <input
          name={groupId}
          id={characterItem.label}
          checked={isSelected}
          type='radio'
          onChange={onChange}
          value={characterItem.label}
        />
        <ThumbContainer
          isSelected={isSelected}
          backgroundImage={`url(${characterItem.bg})`}
          backgroundPosition='center'
        >
          <ThumbImg isSelected={isSelected} src={characterItem.avatar} alt={characterItem.alt} />
        </ThumbContainer>

        <Text
          mt='1.125rem'
          value={characterItem.label}
          fontSize={isMobile ? fontSizeMobile : fontSize}
          lineHeight='1.5'
          font='body2'
          textDecoration={isSelected ? 'underline' : 'none'}
        />
      </Container>
    </AvatarContainer>
  )
}

export default CharacterItem
