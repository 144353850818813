import { useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox } from '../../../../../components/checkbox'
import Container from '../../../../../components/container'
import Text from '../../../../../components/text'

import { DEFAULT_ERROR_MSG } from '../../../../../utils/schemaToYup'
import {
  Link,
  MultipleCheckboxBadged,
  MultipleCheckboxBadgedContainer,
  MultipleCheckboxContainer,
  MultipleCheckboxWrapper
} from '../../styles'
import { TLayoutProps } from '../../../../../types/types'
import { TComplexForm } from '../../../../../types/PossibleSteps'
import { TOption } from '../../../../../types/AdditionalTypes'
import { getIsSelected } from '../../utils'
import Icon from '../../../../../components/Icon'
import Input from '../../../../../components/input'
import { getEntries } from '../../../../../utils'

export const MultipleCheckbox: React.FC<TLayoutProps<TComplexForm>> = ({
  stepInfo,
  formikProps
}) => {
  const [currentItem, setCurrentItem] = useState<TOption>()

  const {
    id,
    content: { type, fields }
  } = stepInfo

  const { errors, values, touched, submitCount, setFieldValue } = formikProps || {}

  const selectedItem = (values[id] as TOption[]) || []
  const errorMessage = (touched[id] || submitCount > 0) && errors[id]
  const otherField = selectedItem.find(({ id }) => id === 'outro')
  const [hasDescription, setHasDescription] = useState(false)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    container.current?.scrollTo({ top: 0 })
  }, [stepInfo.id])

  const optionsGroup: Record<string, TOption[]> = useMemo(
    () =>
      (fields as TOption[]).reduce(
        (acc, item) => {
          if (item.description) setHasDescription(true)
          if (item.group) {
            acc[item.group] = [...(acc[item.group] || []), item]
            return acc
          }

          acc.noGroup = [...(acc.noGroup || []), item]
          return acc
        },
        {} as Record<string, TOption[]>
      ),
    [fields]
  )

  useEffect(() => {
    setCurrentItem(undefined)
  }, [optionsGroup])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const content = e.target.value

    const otherIndex = selectedItem.findIndex(({ id }) => id === 'outro')
    selectedItem[otherIndex].description = content

    return setFieldValue(id, selectedItem)
  }

  const handleClick = (item: TOption) => {
    if (type === 'single') return setFieldValue(id, item)

    const prevValues = values[id] || []
    const allLabels = (prevValues as TOption[])?.map(selected => selected.label)
    const addNewItem = !allLabels?.includes(item.label)

    if (addNewItem) {
      if (item.description) setCurrentItem(item)
      return setFieldValue(id, [...prevValues, item])
    }

    if (item.label === currentItem?.label) setCurrentItem(undefined)
    const itemToRemove = allLabels.indexOf(item.label)
    prevValues.splice(itemToRemove, 1)
    return setFieldValue(id, prevValues)
  }

  return (
    <Container
      mx='auto'
      height='100%'
      width='clamp(320px, 720px, 100vw)'
      alignItems='center'
      flexDirection='column'
      justifyContent='flex-start'
      mt='1rem'
      p='1rem'
    >
      {Object.keys(optionsGroup).length > 1 && (
        <ul className='mb-2 flex gap-2'>
          {Object.keys(optionsGroup).map(groups => (
            <li key={groups}>
              <Link href={`#group-init-${groups}`}>{groups}</Link>
            </li>
          ))}
        </ul>
      )}
      <MultipleCheckboxWrapper>
        <MultipleCheckboxContainer ref={container}>
          {getEntries(optionsGroup).map(([key, items], index) => (
            <div key={key} id={`group-init-${key}`}>
              {key !== 'noGroup' && (
                <Text className='pointer-events-none mt-2 text-content-brand'>{key}</Text>
              )}
              {items.map(item => (
                <div key={item.id}>
                  <Checkbox
                    id={item.id}
                    autoFocus={index === 0}
                    label={item.label}
                    variant='secondary'
                    onChange={() => handleClick(item)}
                    checked={getIsSelected(
                      { type } as Pick<TComplexForm, 'type'>,
                      selectedItem,
                      item
                    )}
                  />
                </div>
              ))}
            </div>
          ))}
        </MultipleCheckboxContainer>
      </MultipleCheckboxWrapper>
      {(errorMessage || errorMessage?.id) && (
        <p style={{ position: 'relative' }} id={`error-message-${id}`}>
          {DEFAULT_ERROR_MSG}
        </p>
      )}
      {otherField && (
        <Container width='100%' mt={3}>
          <Input
            required
            label='Especifique o outro'
            placeholder='Especifique o outro'
            value={otherField.description}
            onChange={handleChange}
          />
        </Container>
      )}
      <MultipleCheckboxBadgedContainer>
        {selectedItem.map(item => (
          <MultipleCheckboxBadged
            key={item.id}
            {...(currentItem?.label === item.label && {
              'aria-describedby': 'badged-description'
            })}
          >
            <button type='button' disabled={!item.description} onClick={() => setCurrentItem(item)}>
              <Text font='body' fontSize='0.875rem'>
                {item.label}
              </Text>
            </button>
            <button onClick={() => handleClick(item)} aria-label='deletar item' type='button'>
              <Icon name='close' size={20} />
            </button>
          </MultipleCheckboxBadged>
        ))}
      </MultipleCheckboxBadgedContainer>
      {hasDescription && (
        <Container
          mx='auto'
          mt='1rem'
          alignItems='center'
          flexDirection='column'
          width='clamp(320px, 720px, 95vw)'
          maxWidth='100%'
          minHeight='150px'
        >
          {currentItem?.description ? (
            <Container alignItems='center' id='badged-description'>
              <Text textAlign='center' font='body' fontSize='1.125rem' lineHeight='28px'>
                {currentItem?.description}
              </Text>
            </Container>
          ) : (
            <></>
          )}
        </Container>
      )}
    </Container>
  )
}
