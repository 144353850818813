import error from '../../assets/images/mindBlowing.png'
import Text from '../text'
import Button from '../button'
import { TErrorPage } from './types'
import { StyledError } from './style'

const Error: React.FC<TErrorPage> = ({ description, isInitialError, hideButton }) => {
  return (
    <StyledError>
      <img src={error} alt='' />
      {isInitialError ? (
        <>
          <p>{description || 'Algo deu errado por favor entre em contato conosco!'}</p>
          <button type='button' onClick={() => location.reload()}>
            Recarregue a página!
          </button>
        </>
      ) : (
        <>
          <Text m={4} font='body'>
            {description || 'Algo deu errado por favor entre em contato conosco!'}
          </Text>
          {!hideButton && (
            <Button type='button' onClick={() => location.reload()} value='Recarregue a página!' />
          )}
        </>
      )}
    </StyledError>
  )
}

export default Error
