import { useTheme } from 'styled-components'
import { cepFormatter, onlyNumbers, phoneFormatter } from '../../utils'
import { StyledIcon, StyledInput, StyledInputContainer } from './styles'
import { InputProps } from './types'
import Icon from '../Icon'
import { useEffect, useRef } from 'react'

const Input: React.FC<InputProps> = ({
  id,
  icon,
  label,
  iconName,
  autoFocus,
  required,
  mask,
  type,
  value,
  onChange,
  onBlur,
  placeholder,
  variant,
  errorMessage,
  ...spaceProps
}) => {
  const theme = useTheme()
  const inputElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputElement.current && autoFocus) {
      inputElement.current.focus()
    }
  }, [autoFocus])

  const applyMask = (value: string) => {
    if (mask === 'phone' || type === 'tel') return phoneFormatter(value)
    if (mask === 'cep') return cepFormatter(value)
    if (type === 'number') return onlyNumbers(value)

    return value
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      event.target.value = applyMask(event.target.value)
      onChange(event)
    }
  }

  return (
    <StyledInputContainer {...spaceProps}>
      {iconName && (
        <Icon
          mr='20px'
          name={iconName}
          size={28}
          color={variant === 'secondary' ? theme.colors.secondary : theme.colors.primary}
        />
      )}
      {icon && <StyledIcon icon={icon} />}
      <StyledInput
        ref={inputElement}
        autoFocus={autoFocus}
        id={id}
        name={id}
        aria-label={label}
        aria-invalid={!!errorMessage}
        {...(errorMessage && {
          'aria-describedby': `error-message-${id}`
        })}
        required={required}
        type={type}
        onBlur={onBlur}
        variant={variant}
        value={applyMask(value || '')}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {errorMessage && <p id={`error-message-${id}`}>{errorMessage}</p>}
    </StyledInputContainer>
  )
}

export default Input
