import styled from 'styled-components'

export const AccordionStyle = styled.div`
  button {
    padding: 0;
    border: none;
    cursor: pointer;
    background: none;
    transition: all 100ms;

    strong {
      display: flex;
      align-items: center;

      div {
        margin-left: 5px;
      }
    }

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};

      svg {
        transition: all 300ms;
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }

    &[aria-expanded='true'] + div {
      max-height: 100%;
    }
  }

  > div {
    max-height: 0;
    overflow: hidden;
    transition: all 200ms;
  }

  margin-top: 10px;
`
