export const convertBytes = (bytes: number) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const unit = sizes[i]
  return `${parseFloat((bytes / k ** i).toFixed(2))} ${unit}`
}

export const createUrlForFiles = (files: File[]) => {
  return files.map(file =>
    Object.assign(file, {
      url: URL.createObjectURL(file)
    })
  )
}

export const removeDuplicatedFiles = (files: File[]) => {
  const filesName = files.map(file => file.name)
  const cleanArray = files.filter((item, index) => filesName.indexOf(item.name) === index)
  return cleanArray
}

export const getListOfTypeOfFilesAllowed = (
  typeOfFilesAllowed: Record<string, string[]>
): string => {
  return Object.values(typeOfFilesAllowed).reduce((acc, item) => `${acc}, ${item.join(', ')}`, '')
}
