import RadioOrCheckbox from '../RadioOrCheckbox'
import Container from '../container'
import { StyledChooseButtonsFieldset } from './styles'
import { TRadioButtonsProps } from './types'

export const ChooseButtons: React.FC<TRadioButtonsProps> = ({
  id,
  type,
  label,
  values,
  layout,
  selectedItems,
  errorMessage,
  showLabel,
  onChange,
  variant
}) => {
  const inputType = type === 'radioGroup' ? 'radio' : 'checkbox'
  const isChecked = (label: string) =>
    inputType === 'radio' ? selectedItems === label : selectedItems?.includes(label)

  return (
    <StyledChooseButtonsFieldset
      id={id}
      variant={variant}
      layout={layout}
      aria-label={label}
      {...(errorMessage && {
        'aria-describedby': `error-message-${id}`
      })}
    >
      {showLabel && <legend>{label}</legend>}
      <Container flexDirection={layout || 'column'}>
        {values.map((item, index) => (
          <RadioOrCheckbox
            key={item.id}
            id={id}
            aria-invalid={!!errorMessage}
            layout={layout}
            variant={variant}
            onChange={onChange}
            item={item}
            autoFocus={index === 0}
            isChecked={isChecked(item.label)}
            inputType={inputType}
          />
        ))}
      </Container>
      {errorMessage && <p id={`error-message-${id}`}>{errorMessage}</p>}
    </StyledChooseButtonsFieldset>
  )
}
