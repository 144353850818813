import styled, { css } from 'styled-components'

export const StyledLayoutContainer = styled.div<{
  bgColor: string
  bgImg?: string
  headerHeight?: string
  bgPosition: string
}>`
  min-height: calc(100vh - env(safe-area-inset-top));
  display: flex;
  padding-top: ${props => props.headerHeight || '60px'};
  justify-content: center;
  background-color: ${props => props.bgColor};
  background-repeat: no-repeat;
  background-position: ${({ bgPosition }) => bgPosition};
  ${({ bgImg }) =>
    bgImg &&
    css`
      background-image: ${bgImg};
    `};

  @media screen and (max-width: 768px) {
    padding-top: ${props => props.headerHeight || '60px'};
  }
`
