import Input from '../../components/input'
import { TFieldsOptions } from '../../types/PossibleSteps'
import { ChooseButtons } from '../../components/ChooseButtons'
import { Textarea } from '../../components/textarea'
import AddressInput from '../../components/AddressInput'
import Select from '../../components/Select'

class FormComponentsStrategy {
  static input(item: TFieldsOptions, props: Record<string, unknown>) {
    if (item.type !== 'input') return null

    return (
      <Input
        key={item.id}
        id={item.id}
        type={item.inputType}
        icon={item?.icon}
        iconName={item?.iconName}
        label={item.label}
        placeholder={item.placeholder}
        {...props}
      />
    )
  }

  static address(item: TFieldsOptions, props: Record<string, unknown>) {
    if (item.type !== 'address') return null

    return (
      <AddressInput
        key={item.id}
        id={item.id}
        icon={item?.icon}
        iconName={item?.iconName}
        placeholder={item.placeholder}
        {...props}
      />
    )
  }

  static textArea(item: TFieldsOptions, props: Record<string, unknown>) {
    if (item.type !== 'textArea') return null

    return (
      <Textarea
        key={item.id}
        id={item.id}
        label={item.label}
        placeholder={item.placeholder}
        {...props}
      />
    )
  }

  static radioGroup(item: TFieldsOptions, props: Record<string, unknown>) {
    if (item.type !== 'radioGroup') return null

    return <ChooseButtons key={item.id} {...item} {...props} />
  }

  static checkboxGroup(item: TFieldsOptions, props: Record<string, unknown>) {
    if (item.type !== 'checkboxGroup') return null

    return <ChooseButtons key={item.id} {...item} {...props} />
  }

  static select(item: TFieldsOptions, props: Record<string, unknown>) {
    if (item.type !== 'select') return null

    return <Select key={item.id} {...item} {...props} />
  }
}

export default FormComponentsStrategy
