import styled from 'styled-components'

export const AddLinkInputContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: #fff;
  border-radius: 9px;
  display: flex;

  > div {
    padding: 0.5rem 0.5rem 0.5rem 0.7rem;
    border-right: 2px solid ${({ theme }) => theme.colors.primary};
  }

  p {
    margin: 0;
  }

  input {
    border: none;
    width: 100%;
    margin-right: 5px;
    font-size: 1.125rem;
    font-weight: 700;
    padding-left: 10px;
    ${props => props.theme.fonts.body}

    &:focus {
      outline: none;
      margin-right: 0;
      border-radius: 0 8px 8px 0;
      border: 3px solid ${({ theme }) => theme.colors.secondary};
    }
  }
`
