import cep from 'cep-promise'
import { StyledAddressInputsContainer } from './styles'
import { TAddressInputProps } from './types'
import Input from '../input'
import { useCallback, useState } from 'react'

const AddressInput: React.FC<TAddressInputProps> = ({
  id,
  icon,
  iconName,
  value,
  onChange,
  onBlur,
  placeholder,
  variant,
  errorMessage,
  getErrorMessage,
  formikProps,
  ...spaceProps
}) => {
  const [customError, setCustomError] = useState<string>()
  const { values = {}, setFieldValue = () => null } = formikProps || {}

  const onCepChange = useCallback(
    async (zip: string) => {
      try {
        const addressInfo = await cep(zip.replace('-', ''))
        setCustomError(undefined)

        setFieldValue('state', addressInfo.state)
        setFieldValue('city', addressInfo.city)
      } catch (error) {
        if ((error as Error).name === 'CepPromiseError') setCustomError('Cep não encontrado')
      }
    },
    [setFieldValue]
  )

  const handleChangeCep = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cep = event?.target.value
    if (cep.length === 9) onCepChange(cep)

    onChange?.(event)
  }

  return (
    <StyledAddressInputsContainer {...spaceProps}>
      <Input
        id={id}
        type='text'
        mask='cep'
        label='CEP'
        icon={icon}
        value={value}
        onBlur={onBlur}
        variant={variant}
        iconName={iconName}
        onChange={handleChangeCep}
        placeholder={placeholder}
        errorMessage={customError || errorMessage}
        autoFocus
      />
      <Input
        id='state'
        type='text'
        icon={
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_81_4312)'%3E%3Cpath d='M12 12C13.1 12 14 11.1 14 10C14 8.9 13.1 8 12 8C10.9 8 10 8.9 10 10C10 11.1 10.9 12 12 12ZM12 2C16.2 2 20 5.22 20 10.2C20 13.38 17.55 17.12 12.66 21.43C12.28 21.76 11.71 21.76 11.33 21.43C6.45 17.12 4 13.38 4 10.2C4 5.22 7.8 2 12 2Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_81_4312'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E\")"
        }
        label='Estado'
        placeholder='Estado'
        value={values['state'] as string}
        variant={variant}
        onBlur={onBlur}
        onChange={onChange}
        errorMessage={getErrorMessage?.('state') || ''}
      />
      <Input
        id='city'
        type='text'
        icon={
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_81_4898)'%3E%3Cpath d='M18.9996 9.3007V5.0007C18.9996 4.4507 18.5496 4.0007 17.9996 4.0007H16.9996C16.4496 4.0007 15.9996 4.4507 15.9996 5.0007V6.6007L12.6696 3.6007C12.2896 3.2607 11.7096 3.2607 11.3296 3.6007L2.96961 11.1307C2.62961 11.4307 2.83961 12.0007 3.29961 12.0007H4.99961V19.0007C4.99961 19.5507 5.44961 20.0007 5.99961 20.0007H8.99961C9.54961 20.0007 9.99961 19.5507 9.99961 19.0007V14.0007H13.9996V19.0007C13.9996 19.5507 14.4496 20.0007 14.9996 20.0007H17.9996C18.5496 20.0007 18.9996 19.5507 18.9996 19.0007V12.0007H20.6996C21.1596 12.0007 21.3796 11.4307 21.0296 11.1307L18.9996 9.3007ZM9.99961 10.0007C9.99961 8.9007 10.8996 8.0007 11.9996 8.0007C13.0996 8.0007 13.9996 8.9007 13.9996 10.0007H9.99961Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_81_4898'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E\")"
        }
        label='cidade'
        placeholder='Cidade'
        onBlur={onBlur}
        variant={variant}
        value={values['city'] as string}
        onChange={onChange}
        errorMessage={getErrorMessage?.('city') || ''}
      />
    </StyledAddressInputsContainer>
  )
}

export default AddressInput
