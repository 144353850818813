import React from 'react'
import {
  StyledCardMultiLayersContainer,
  StyledLayerMain,
  StyledLayerOne,
  StyledLayerTwo
} from './styled'

export const CardMultiLayers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <StyledCardMultiLayersContainer>
      <StyledLayerOne />
      <StyledLayerTwo />
      <StyledLayerMain>{children}</StyledLayerMain>
    </StyledCardMultiLayersContainer>
  )
}
