import { StyledButton } from './styled'
import { ButtonProps } from './types'

const Button: React.FC<ButtonProps> = ({
  onClick,
  value,
  children,
  variant = 'primary',
  type = 'button',
  ...spaceProps
}) => {
  return (
    <StyledButton type={type} {...spaceProps} onClick={onClick} variant={variant}>
      {value || children}
    </StyledButton>
  )
}

export default Button
