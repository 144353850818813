import styled from 'styled-components'

export const UploadFileWrapper = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  padding: 0 20px;
`

export const UploadContainer = styled.section`
  .dropzone {
    width: 100%;
    border: 2px dashed ${({ theme }) => theme.colors.primaryLight};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem 0 1.5rem 0;
  }
`

/* ${media.lessThan('small')`
  display: flex;
  flex-direction: column-reverse;
`} */
export const FilesDragWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  > div {
    margin-right: -55px;
    z-index: 2;
  }

  @media screen and (max-width: 500px) {
    margin-top: 0.5rem;
    flex-direction: column;
    > div {
      margin: 0 0 -1rem;
    }
  }
`

export const FilesDragContainer = styled.section`
  padding: 1rem;
  width: max-content;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: #fff;
  border-radius: 9px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 2rem;

    @media screen and (max-width: 500px) {
      margin: 0;
    }
  }

  button {
    font-size: 1.2rem;
    padding-top: 0;
  }
`

export const FilesPreviewContainer = styled.section`
  max-height: 72px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem 0 3rem;

  @media screen and (max-width: 500px) {
    padding: 1rem;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 0;

      display: grid;
      grid-template-columns: 1fr 30px;

      a {
        font-weight: 600;
        font-size: 1rem;
        letter-spacing: 0.004em;

        color: ${({ theme }) => theme.colors.secondary};
        margin-right: 0.5rem;
      }

      [data-testid='fileName'] {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        > div {
          display: inline;
        }
      }

      button {
        background: transparent;
        border: none;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
`
