import styled, { css } from 'styled-components'
import {
  background,
  border,
  flexbox,
  layout,
  position,
  space,
  grid,
  textAlign,
  boxShadow
} from 'styled-system'

export const StyledContainer = styled.div<{ as: keyof JSX.IntrinsicElements }>`
  transition: all 300ms;
  display: flex;

  border: none;
  background: none;
  scroll-behavior: smooth;
  ${layout}
  ${space}	
	${flexbox}
	${position}
	${border}
	${background}
	${grid}
	${textAlign}
  ${boxShadow}

  ${({ as, theme }) =>
    as === 'main' &&
    css`
      *::selection {
        color: ${theme.colors.white};
        background-color: ${theme.colors.secondaryLight};
      }
    `}
`
