import styled, { css } from 'styled-components'
import { space } from 'styled-system'

export const StyledSelectItemWrapper = styled.div`
  position: relative;
  margin: 0 auto 10px;
`

export const StyledSelectItemContainer = styled.label<{ isSelected?: boolean }>`
  display: flex;
  min-height: 110px;
  width: 110px;
  position: relative;
  border-radius: 15px;
  border: 2px solid;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.secondary : theme.colors.gray[800]};
  ${space}

  &:focus, &:hover, &:focus-within {
    border-color: ${({ theme }) => theme.colors.secondary};
    + [data-testid='label'] {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  img {
    max-width: 100%;
  }

  input {
    position: absolute;
    opacity: 0;
  }
`

export const StyledSelectItemLabel = styled.div<{
  relativeLabel?: boolean
  isSelected?: boolean
  label?: string
}>`
  padding: 2px;
  font-family: 'Bungee', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.secondary : theme.colors.gray[800]};
  position: absolute;
  bottom: -10px;
  right: ${props => (props?.label && props?.label?.length > 40 ? '-10px' : '10px')};

  width: min-content;
  min-width: 80%;
  left: 50%;
  transform: translate(-50%, 0);

  ${props =>
    props?.relativeLabel &&
    css`
      position: relative;
      min-width: 110%;
    `}
`
