import styled from 'styled-components'

export const AvatarLogoWrapper = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${props => props.size || '180px'};
`

export const AvatarLogoContainer = styled.div<{ bg?: string; size?: string }>`
  width: ${props => props.size || '180px'};
  height: ${props => props.size || '180px'};

  @media screen and (max-width: 768px) {
    width: ${props => props.size || '110px'};
    height: ${props => props.size || '110px'};
  }

  justify-content: center;
  overflow: visible;
  display: flex;
  background: ${({ bg }) => (bg ? `url(${bg})` : '#fff')};
  align-items: center;
  background-size: cover;

  img {
    max-width: 100%;
  }
`
