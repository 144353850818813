import TagManager from 'react-gtm-module'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { useEffect } from 'react'
import LayoutContextProvider from './context/LayoutContext'
import FormContextProvider, { useFormContext } from './context/FormContext'
import GlobalStyle from './globalStyle'
import { getTheme } from './theme'
import router from './router'
import 'semente-js/styles.css'
import './styles/index.css'

const FormInfo = () => {
  const form = useFormContext()

  useEffect(() => {
    if (form.gtmId) TagManager.initialize({ gtmId: form.gtmId })
  }, [form.gtmId])

  return (
    <ThemeProvider theme={getTheme(form)}>
      <LayoutContextProvider>
        <RouterProvider router={router} />
      </LayoutContextProvider>
    </ThemeProvider>
  )
}

const App = () => (
  <>
    <GlobalStyle />
    <FormContextProvider>
      <FormInfo />
    </FormContextProvider>
  </>
)

export default App
