import Container from '../../../../../components/container'
import Text from '../../../../../components/text'
import { Swiper, SwiperSlide } from 'swiper/react'

import CharacterItem from './CharacterItem'
import { useFormContext } from '../../../../../context/FormContext'
import { TAvatar } from '../../../../../types/AdditionalTypes'
import { TLayoutProps } from '../../../../../types/types'
import { TComplexForm } from '../../../../../types/PossibleSteps'

export const Avatar: React.FC<TLayoutProps<TComplexForm>> = ({ stepInfo, formikProps }) => {
  const {
    id,
    title,
    content: { fields, carouselOptions }
  } = stepInfo
  const avatarList = fields as TAvatar[]
  const { avatarInfo } = useFormContext()
  const { values, setFieldValue } = formikProps || {}

  return (
    <Container
      mx='auto'
      height='100%'
      width='clamp(320px, 720px, 100vw)'
      alignItems='center'
      display='grid'
      alignContent='center'
      px={2}
    >
      <Swiper initialSlide={avatarInfo?.slideIndex || 0} {...carouselOptions}>
        <fieldset aria-label={title}>
          {avatarList?.map(item => (
            <SwiperSlide key={item.label}>
              <CharacterItem
                groupId={id}
                characterItem={item}
                onClick={(slideIndex: number) => setFieldValue(id, { ...item, slideIndex })}
                isSelected={values[id]?.label === item.label}
              />
            </SwiperSlide>
          ))}
        </fieldset>
      </Swiper>
      <Text textAlign='center' fontSize='1.125rem' lineHeight='28px' font='body' py={2}>
        {values[id]?.description}
      </Text>
    </Container>
  )
}
