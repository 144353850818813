import styled from 'styled-components'
import Container from '../../../../../components/container'
import { IsSelectedProp } from './types'

export const AvatarContainer = styled.label`
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  &:focus {
    color: ${props => props.theme.colors.secondary};
  }
`

export const ThumbContainer = styled(Container)<IsSelectedProp>`
  width: ${props => (props.isSelected ? '160px' : '110px')};
  height: ${props => (props.isSelected ? '160px' : '110px')};
  filter: ${props => (props.isSelected ? 'none' : 'grayscale(80%) opacity(90%)')};
  text-decoration: ${props => (props.isSelected ? 'underline' : 'none')};

  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-size: cover;

  input {
    position: absolute;
    opacity: 0;
  }

  @media screen and (max-width: 768px) {
    width: ${props => (props.isSelected ? '140px' : '110px')};
    height: ${props => (props.isSelected ? '140px' : '110px')};
  }
`

export const ThumbImg = styled.img<IsSelectedProp>`
  width: ${props => (props.isSelected ? '160px' : '120px')};
  height: ${props => (props.isSelected ? '160px' : '120px')};

  @media screen and (max-width: 768px) {
    width: ${props => (props.isSelected ? '140px' : '110px')};
    height: ${props => (props.isSelected ? '140px' : '110px')};
  }
`
