import Container from '../../../components/container'
import { useFormContext } from '../../../context/FormContext'
import { TComplexFormComponent } from './types'
import Button from '../../../components/button'
import ComplexLayoutStrategy from '../../../utils/strategy/complexLayoutStrategy'

import 'swiper/css'
import 'swiper/css/navigation'
import './Layout/styles_choose_character.scss'
import { Formik, FormikHelpers } from 'formik'
import { ConnectedFocusError } from 'focus-formik-error'
import { StyledForm } from '../SimpleStep/styles'
import { TAvatar, TOption } from '../../../types/AdditionalTypes'
import { renderModules } from './utils'
import { yupValidationFactory } from '../../../utils/schemaToYup'
import AvatarLogo from '../../../components/AvatarLogo'

const ComplexForm: React.FC<TComplexFormComponent> = ({ stepInfo }) => {
  const {
    goToNextStep,
    goToPreviousStep,
    avatarInfo,
    setAvatarInfo,
    currentStep,
    steps,
    editFormContent,
    formContent,
    ...formData
  } = useFormContext()
  const {
    id,
    logoFromChooseAvatar,
    content: { type, hideOptionFromQuestion, carouselOptions, fields, yup }
  } = stepInfo

  if (hideOptionFromQuestion) {
    const options = JSON.parse(JSON.stringify(fields)) as TOption[]
    const itemToHide = formContent[hideOptionFromQuestion] as TOption
    stepInfo.content.fields = options.filter(({ label }) => label !== itemToHide.label)
  }

  const getInitialValue = () => {
    const defaultValue = type === 'single' ? {} : []
    const initialValues = (formContent[id] || defaultValue) as Record<string, unknown>
    if (type !== 'avatar') return { [id]: initialValues }

    const avatarList = fields as TAvatar[]
    if (avatarList && !avatarInfo) {
      initialValues[id] = avatarList[0]

      return initialValues
    }

    if (type === 'avatar' && Array.isArray(initialValues)) return { [id]: initialValues[0] }

    return { [id]: initialValues }
  }

  const initialValues = getInitialValue()
  const showActionsButtons = currentStep !== 0 && currentStep !== steps.length - 1
  const stepComponent = () => ComplexLayoutStrategy[type]
  const yupValidationSchema = yupValidationFactory([{ id, yup }])
  const showName =
    logoFromChooseAvatar?.showName !== undefined ? logoFromChooseAvatar?.showName : true

  const handleSubmitForm = (
    values: Record<string, unknown>,
    { resetForm }: FormikHelpers<Record<string, unknown>>
  ) => {
    if (id === 'avatar') setAvatarInfo(values['avatar'] as TAvatar)
    editFormContent({ [id]: values[id] || values })
    resetForm({ values: {} })
    goToNextStep()
  }

  if (carouselOptions) carouselOptions.modules = renderModules(carouselOptions)

  return (
    <>
      {logoFromChooseAvatar ? (
        <Container justifyContent='center'>
          <AvatarLogo stepInfo={stepInfo} showName={showName} size='150px' />
        </Container>
      ) : null}
      <Formik
        enableReinitialize
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        validationSchema={yupValidationSchema}
      >
        {({ handleSubmit, ...formikProps }) => (
          <StyledForm onSubmit={handleSubmit} noPadding>
            <ConnectedFocusError />
            {stepComponent()({ stepInfo, formikProps })}
            {showActionsButtons && (
              <Container
                p='2rem 1rem'
                m='auto'
                width='100%'
                alignItems='center'
                justifyContent='space-between'
                flexDirection='row-reverse'
              >
                <Button type='submit' value={formData.nextBtn || 'PRÓXIMO >'} />
                <Button
                  onClick={goToPreviousStep}
                  variant='outline'
                  value={formData.backBtn || '< ANTERIOR'}
                />
              </Container>
            )}
          </StyledForm>
        )}
      </Formik>
    </>
  )
}

export default ComplexForm
