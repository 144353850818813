import { TAvatar } from './../types/AdditionalTypes'

export const getAvatarVariationUrl = (avatarInfo?: TAvatar, avatarVariation?: string) => {
  if (!avatarInfo || !avatarVariation) return avatarInfo?.avatar
  const {
    length,
    [length - 1]: justImageName,
    ...imageHostDestructured
  } = avatarInfo.avatar.split('/') //should be 5

  const [avatarUrl, imgExtension] = justImageName.split('.')
  const mainAvatarUrl = avatarUrl.split('_')[0]
  const imageHost = Object.values(imageHostDestructured).join('/')

  return `${imageHost}/${mainAvatarUrl}_${avatarVariation}.${imgExtension}`
}
