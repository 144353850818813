import { useState } from 'react'
import { TOption } from '../../types/AdditionalTypes'
import AccordionItem from './AccordionItem'

type TAccordionGroup = { items: TOption[] }

const AccordionGroup: React.FC<TAccordionGroup> = ({ items }) => {
  const [openAccordion, setOpenAccordion] = useState<string>()

  const handleClick = (label: string) => {
    const newValue = label === openAccordion ? undefined : label
    setOpenAccordion(newValue)
  }

  return (
    <>
      {items.map(item => (
        <AccordionItem
          key={item.id}
          item={item}
          isOpen={openAccordion === item.label}
          handleClick={() => handleClick(item.label)}
        />
      ))}
    </>
  )
}

export default AccordionGroup
