const updateOrCreateMeta = (name: string, content: string) => {
  const metaTag = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement
  if (metaTag) return (metaTag.content = content)

  const newMeta = document.createElement('meta')
  newMeta.name = name
  newMeta.content = content

  document.getElementsByTagName('head')[0].appendChild(newMeta)
}

export const appendMetaTags = (meta: Record<string, string>) => {
  Object.entries(meta).map(([name, content]) => updateOrCreateMeta(name, content))
}

export const appendFontLink = (linkHref: string) => {
  const fontLink = document.createElement('link')
  fontLink.href = linkHref
  fontLink.rel = 'stylesheet'

  document.getElementsByTagName('head')[0].appendChild(fontLink)
}
