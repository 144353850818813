import styled from 'styled-components'
import { color, fontSize, fontWeight, lineHeight, space, textAlign } from 'styled-system'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledText = styled.div<any>`
  display: inline;

  p {
    margin: 5px;
    + p {
      margin-top: 7px;
    }
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (max-width: 768px) {
    p {
      margin: 0;
    }
  }

  ol {
    counter-reset: listItems;

    li {
      display: inline-block;
      padding: 0 10px;

      &:before {
        content: counter(listItems) '.';
        counter-increment: listItems;
        padding-right: 0.25em;
      }
    }
  }

  ${props => props.theme.fonts[props.font]}
  ${space}
  ${color}
  ${fontSize}
  ${textAlign}
  ${fontWeight}
  ${lineHeight}
  ${props => props.textDecoration && `text-decoration: ${props.textDecoration};`}
`
