import styled, { css } from 'styled-components'
import { ReactComponent as LoadingIcon } from '../../assets/images/Loading.svg'

const StyledLoading = styled.div<{ color?: string; fullPage?: boolean }>`
  margin: auto;
  ${props =>
    props.fullPage &&
    css`
      display: flex;
      height: 100vh;
      width: 100%;
    `};

  circle {
    fill: ${props => props.color || props.theme.colors.secondary};
  }
`

const Loading: React.FC<{ color?: string; fullPage?: boolean }> = ({ color, fullPage }) => {
  return (
    <StyledLoading color={color} fullPage={fullPage}>
      <LoadingIcon />
    </StyledLoading>
  )
}

export default Loading
